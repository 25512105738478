import _ from 'lodash'
import {
  downloadPdfFromBase64,
  getPdfNameOfPreliminaryAssessment,
  getTypeOfId,
  shouldChangeTheApplicationStatusToAwaitingDocs,
  shouldChangeTheApplicationStatusToAwaitingDocsForBankStatement,
} from './methods'

import {
  DEFAULT_EXPENSE_CONSTANT,
  DEFAULT_VEHICLE_GROUP_OBJECT,
  LABEL_APPROVED,
  LABEL_DECLINED,
  LABEL_WITHDRAWN,
  LABEL_AUSTRALIA,
  LIVING_SITUATION_OWN_HOME_WITH_MORTGAGE,
  APPLICATION_TRADE_IN_VEHICLE,
  APPLICATION_STATUS_WITHDRAWN,
  APPLICATION_STATUS_DECLINED,
  APPLICATION_STATUS_APPROVED,
  LABEL_FOR_COMMERCIAL,
  APPLICATION_LINK_STATUS_MARK_AS_REVIEWED,
  APPLICATION_LINK_STATUS_SENT,
  LABEL_DOCUMENT_PRIMARY,
  LABEL_DOCUMENT_SECONDARY,
  LABEL_SHARED,
  LOAN_TYPE_PERSONAL_LOAN,
  LABEL_DOCUMENT_SETTLEMENT,
  LABEL_DIGITAL,
  INCOME_EXPENSE_FREQUENCY_MONTHLY,
  ERROR_MESSAGE_FOR_USER_MOBILE_NUMBER_ALREADY_EXISTS,
  ERROR_MESSAGE_FOR_USER_EMAIL_ALREADY_EXISTS,
  ERROR_MESSAGE_FOR_USER_INFO_ALREADY_EXISTS,
  ERROR_MESSAGE_FOR_ENTERED_MOBILE_NO,
  ERROR_MESSAGE_FOR_ENTERED_EMAIL,
} from '~/constants/applications'

import {
  APPLICATION_STATUS_STARTED,
  APPLICATION_STATUS_PRE_SETTLEMENT,
  APPLICATION_STATUS_SUBMITTED_TO_LENDER,
  APPLICATION_STATUS_SETTLED,
  SETTLEMENT_STATUS_INPROGRESS,
  CREDIT_CHECK_SUCCESS_MESSAGE,
  REFETCH_FILE_CHECK_SUCCESS_MESSAGE,
  APPLICATION_STATUS_READY_FOR_SUBMISSION,
  APPLICATION_STATUS_AWAITING_DOCS,
  LABEL_CONSUMER,
  EXPENSE_TYPE_HOUSEHOLD,
} from '~/constants/index'
import { bindLiabilityWithAssetIfHas } from '~/helpers/application'
import { axiosErrorMessageExtractor } from '~/utils/error'
import { sub } from 'date-fns'
import {
  LABEL_COMMERCIAL_CAR_LOAN,
  LABEL_CONSUMER_CAR_LOAN,
} from '~/constants/referrals'
const abstractVehicleData = (data, vehicleResponse) => {
  let vehicleObj = null
  if (vehicleResponse) {
    data.vehicle = vehicleResponse?.data
      ? vehicleResponse.data
      : vehicleResponse
    data.vehicle.customerNote = data.vehicle?.customerNote || ''
    data.vehicle.vIN = data.vehicle?.vIN || ''
    data.vehicle.registrationPlate = data.vehicle?.registrationPlate || ''
    data.vehicle.purchaseSource = data.vehicle?.purchaseSource || ''
    data.vehicle.condition = data.vehicle?.condition || ''
    data.vehicle.makeCode = data.vehicle?.makeCode || ''
    data.vehicle.makeDescription = data.vehicle?.makeDescription || ''
    data.vehicle.code = data.vehicle?.code || ''
    data.vehicle.codeDescription = data.vehicle?.codeDescription || ''
    data.vehicle.assetDetail = data.vehicle?.assetDetail || ''
    data.vehicle.familyCode = data.vehicle?.familyCode || ''
    data.vehicle.familyDescription = data.vehicle?.familyDescription || ''
    data.vehicle.assetType = data.vehicle?.assetType || ''
    data.vehicle.account = data.vehicle?.account || ''
    data.vehicle.financeDepositAmount = data.vehicle?.financeDepositAmount || ''
    data.vehicle.type = data.vehicle?.type || ''
    data.vehicle.use = data.vehicle?.use || ''
    data.vehicle.year = data.vehicle?.year || ''
    data.vehicle.yearDescription = data.vehicle?.yearDescription || ''
  } else {
    vehicleObj = {
      assetType: '',
      vIN: '',
      registrationPlate: '',
      customerNote: '',
      assetDetail: '',
      condition: '',
      purchaseSource: '',
      _id: data.vehicle,
      account: '',
      code: '',
      codeDescription: '',
      familyCode: '',
      familyDescription: '',
      financeDepositAmount: '',
      makeCode: '',
      makeDescription: '',
      type: '',
      use: '',
      year: '',
      yearDescription: '',
    }
    data.vehicle = vehicleObj
  }
}

const abstractTradeVehicleData = (data, vehicleResponse) => {
  if (vehicleResponse) {
    data.tradeIn = vehicleResponse?.data
      ? vehicleResponse.data
      : vehicleResponse
    data.tradeIn.customerNote = data.tradeIn?.customerNote || ''
    data.tradeIn.vIN = data.tradeIn?.vIN || ''
    data.tradeIn.registrationPlate = data.tradeIn?.registrationPlate || ''
    data.tradeIn.purchaseSource = data.tradeIn?.purchaseSource || ''
    data.tradeIn.condition = data.tradeIn?.condition || ''
    data.tradeIn.makeCode = data.tradeIn?.makeCode || ''
    data.tradeIn.makeDescription = data.tradeIn?.makeDescription || ''
    data.tradeIn.code = data.tradeIn?.code || ''
    data.tradeIn.codeDescription = data.tradeIn?.codeDescription || ''
    data.tradeIn.assetDetail = data.tradeIn?.assetDetail || ''
    data.tradeIn.familyCode = data.tradeIn?.familyCode || ''
    data.tradeIn.familyDescription = data.tradeIn?.familyDescription || ''
    data.tradeIn.assetType = data.tradeIn?.assetType || ''
    data.tradeIn.account = data.tradeIn?.account || ''
    data.tradeIn.financeDepositAmount = data.tradeIn?.financeDepositAmount || ''
    data.tradeIn.type = data.tradeIn?.type || ''
    data.tradeIn.use = data.tradeIn?.use || ''
    data.tradeIn.year = data.tradeIn?.year || ''
    data.tradeIn.yearDescription = data.tradeIn?.yearDescription || ''
  } else {
    data.tradeIn = null
  }
}

const deleteLoanObject = (vrgPayload) => {
  if (!vrgPayload?.loanDetails?.lender) {
    delete vrgPayload.loanDetails
  }
}
const areAllCreditCommitmentsUptoDate = (data) => {
  if (
    data &&
    typeof data?.primaryApplicantData?.areAllCreditCommitmentsUptoDate !==
      'boolean'
  ) {
    data.primaryApplicantData.areAllCreditCommitmentsUptoDate = undefined
  }
}
const setLoanDetailsObj = (data) => {
  if (!data.loanDetails) {
    data.loanDetails = {
      lender: '',
      doesApplicantAgreeWithProposedLoanDetails: false,
      doesSatisfyConsumersObjectivesAndRequirements: false,
      doesApplicantComplyWithFinancialObligations: false,
      doesFutureChangesImpactPropsedLoan: false,
      isNoKnownOrLikelyFutureChangeInCircumstancesAgreed: false,
      howDoesSelectedLenderMeetCustomersNeed: '',
      netAmountFinanced: '',
      preliminaryAssessmentAt: null,
      creditProposalDisclosureAt: null,
      creditProposalDisclosureSentAt: null,
    }
  }

  const loanDetailsCommonObj = {
    purchasePrice: data.purchasePrice,
    tradeInAmount:
      data?.tradeIn?.type === APPLICATION_TRADE_IN_VEHICLE
        ? data?.tradeIn?.tradeInAmount
        : '',
    depositAmount: data.vehicle?.financeDepositAmount
      ? data.vehicle?.financeDepositAmount
      : '',
    balloonPct: data.balloonPct,
    loanTermYears: data.loanTermYears,
    balloonAmount: data.balloonAmount,
    monthlyRepayment: '',
    baseRate: '',
    customerRate: '',
    netAmountFinanced: '',
    accountKeepingFee: '',
    lenderEstablishmentFee: '',
    partnerFinanceReferralFee: '',
    ppsrFee: '',
    brokerFee: '',
    lendingCommision: '',
  }

  if (!data.loanDetails.submission) {
    data.loanDetails.submission = {
      ...loanDetailsCommonObj,
      sendProposalAt: '',
    }
  }

  if (
    data.loanDetails.submission &&
    !data.loanDetails.submission.purchasePrice
  ) {
    data.loanDetails.submission.purchasePrice = ''
  }

  if (!data.loanDetails.approval) {
    data.loanDetails.approval = decisionDataBasedOnSubmissionData(
      data.loanDetails
    )
  }
}
const SetApplicantIdType = (data) => {
  if (
    !data.primaryApplicantData.primaryApplicant.idType &&
    Array.isArray(data.primaryApplicantData?.primaryApplicant?.idTypes) &&
    data.primaryApplicantData?.primaryApplicant?.idTypes?.length
  ) {
    data.primaryApplicantData.primaryApplicant.idType = getTypeOfId(
      data.primaryApplicantData.primaryApplicant.idTypes
    )
  }
}
const LoanDetailsreasonStatus = (data) => {
  if (data && !data.withdrawnReasonType) {
    data.withdrawnReasonType = ''
    data.withdrawnReason = ''
  }
  if (data && !data.declinedReasonType) {
    data.declinedReasonType = ''
    data.declinedReason = ''
  }
}

const decisionDataBasedOnSubmissionData = (loanData) => {
  const { submission, approval } = loanData
  if (!approval) {
    return {
      ...approval,
      purchasePrice: submission.purchasePrice,
      tradeInAmount: submission.tradeInAmount,
      depositAmount: submission.depositAmount,
      balloonPct: submission.balloonPct,
      loanTermYears: submission.loanTermYears,
      balloonAmount: submission.balloonAmount,
      netAmountFinanced: submission.netAmountFinanced,
      monthlyRepayment: submission.monthlyRepayment,
      baseRate: submission.baseRate,
      customerRate: submission.customerRate,
      accountKeepingFee: submission.accountKeepingFee,
      lenderEstablishmentFee: submission.lenderEstablishmentFee,
      partnerFinanceReferralFee: submission.partnerFinanceReferralFee,
      ppsrFee: submission.ppsrFee,
      brokerFee: submission.brokerFee,
      lendingCommision: submission.lendingCommision,
      outcomeOfAnApplication: '',
      approvalExpiresAt: '',
      settlementEta: '',
      approvalConditions: '',
      withdrawnReasonType: '',
      withdrawnReason: '',
      declinedReasonType: '',
      declinedReason: '',
      doesApprovedAsSubmitted: null,
    }
  }
}

const settlementDataBasedOnDecisionData = (loanData) => {
  const { approval, settlement } = loanData
  if (!settlement) {
    return {
      ...approval,
      purchasePrice: approval.purchasePrice,
      tradeInAmount: approval.tradeInAmount,
      depositAmount: approval.depositAmount,
      balloonPct: approval.balloonPct,
      loanTermYears: approval.loanTermYears,
      balloonAmount: approval.balloonAmount,
      monthlyRepayment: approval.monthlyRepayment,
      netAmountFinanced: approval.netAmountFinanced,
      baseRate: approval.baseRate,
      customerRate: approval.customerRate,
      accountKeepingFee: approval.accountKeepingFee,
      lenderEstablishmentFee: approval.lenderEstablishmentFee,
      partnerFiananceReferralFee: approval.partnerFiananceReferralFee,
      ppsrFee: approval.ppsrFee,
      brokerFee: approval.brokerFee,
      lendingCommision: approval.lendingCommision,
      outcomeOfAnApplication: '',
      approvalExpiresAt: '',
      approvalConditions: '',
      withdrawnReasonType: '',
      withdrawnReason: '',
      declinedReasonType: '',
      declinedReason: '',
      doesApprovedAsSubmitted: null,
    }
  }
}

const setBusinessDataObj = (data) => {
  if (!data.businessData) {
    data.businessData = {
      businessName: '',
      tradingName: '',
      businessStructure: '',
      tradingYearsRange: '',
      aBN: '',
      hasABN: false,
      aBNRegisteredYearsRange: '',
      isRegisteredForGST: false,
      gSTRegisteredYearsRange: '',
      assets: [],
      liabilities: [],
      grossTradingIncomeMonthly: 0,
      directors: [],
      billingAddress: {
        address: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        atYears: null,
        atAdditionalMonths: null,
        isCurrent: false,
        livingSituation: '',
        isBusinessAddressSameAsResidencial: false,
      },
      completedBusinessInfoAt: '',
      aSIC: '',
      isPropertyOwner: false,
      aBNEffectiveFrom: '',
      gSTEffectiveFrom: '',
      trusteeBusinessName: '',
      trusteeGSTEffectiveFrom: null,
      trusteeABN: '',
      trusteeASIC: '',
      trusteeBillingAddress: {
        state: '',
        postcode: '',
        country: 'Australia',
        isCurrent: false,
      },
      trusteeBusinessStructure: '',
      trusteeABNEffectiveFrom: null,
      noOfDirectors: null,
      noOfPartners: null,
      occupation: null,
      annualTurnover: null,
      industry: '',
      accountant: {
        name: '',
        contactName: '',
        phone: '',
        email: '',
      },
      shareHolders: [],
      beneficiaries: [],
      trusteeType: '',
      trusteeFirstName: '',
      trusteeLastName: '',
      trusteeDirectors: [],
      noOfTrusteeDirectors: null,
      trusteeACN: null,
    }
  }

  if (!data.businessData.trusteeACN) data.businessData.trusteeACN = null
  if (!data.businessData.trusteeType) data.businessData.trusteeType = ''
  if (!data.businessData.trusteeFirstName)
    data.businessData.trusteeFirstName = ''
  if (!data.businessData.trusteeLastName) data.businessData.trusteeLastName = ''
  if (!data.businessData.trusteeBusinessName)
    data.businessData.trusteeBusinessName = ''
  if (!data.businessData.aBNEffectiveFrom)
    data.businessData.aBNEffectiveFrom = ''
  if (!data.businessData.aBN) data.businessData.aBN = ''
  if (!data.businessData.businessName) data.businessData.businessName = ''
  if (!data.businessData.businessStructure)
    data.businessData.businessStructure = ''
  if (!data.businessData.isRegisteredForGST)
    data.businessData.isRegisteredForGST = false
  if (!data.businessData.trusteeDirectors)
    data.businessData.trusteeDirectors = []
  if (!data.businessData.noOfTrusteeDirectors)
    data.businessData.noOfTrusteeDirectors = null
  if (!data.businessData.gSTEffectiveFrom)
    data.businessData.gSTEffectiveFrom = ''

  if (!data.businessData.beneficiaries) data.businessData.beneficiaries = []
  if (!data.businessData.noOfDirectors) data.businessData.noOfDirectors = null
  if (!data.businessData.noOfPartners) data.businessData.noOfPartners = null
  if (!data.businessData.occupation) data.businessData.occupation = null
  if (!data.businessData.annualTurnover) data.businessData.annualTurnover = null
  if (!data.businessData.industry) data.businessData.industry = ''
  if (!data.businessData.accountant)
    data.businessData.accountant = {
      name: '',
      contactName: '',
      phone: '',
      email: '',
    }
  if (!data.businessData.shareHolders) data.businessData.shareHolders = []
  if (!data.businessData.beneficiaries) data.businessData.beneficiaries = []
  if (
    data.businessData.isBusinessAddressSameAsResidence === null ||
    data.businessData.isBusinessAddressSameAsResidence === undefined
  ) {
    data.businessData.isBusinessAddressSameAsResidence = null
  }
}

const updateExpenses = (expenses = [], applicationType) => {
  let mappedExpenses = []
  if (Array.isArray(expenses) && expenses.length === 0) {
    mappedExpenses = DEFAULT_EXPENSE_CONSTANT
  } else {
    mappedExpenses = DEFAULT_EXPENSE_CONSTANT.map(
      (dftPE) => expenses.find((exp) => exp.type === dftPE.type) || dftPE
    )
    // add household expense if avaiable for commercial application
    const data = expenses.find((exp) => exp.type === EXPENSE_TYPE_HOUSEHOLD)
    if (data && applicationType === LABEL_FOR_COMMERCIAL) {
      mappedExpenses.push(data)
    }
  }
  return mappedExpenses
}
const getOtherApplicantsPersonalData = async (otherApplicant, $axios) => {
  const otherUserRequestUrl = `/user/${otherApplicant.applicant}`
  const otherUserResponse = await $axios.get(otherUserRequestUrl)
  otherApplicant.applicant = otherUserResponse.data
  return otherApplicant
}

const getOtherApplicantData = async (data, $axios) => {
  const otherApplicants = await Promise.all(
    data.otherApplicants
      .map((otherApplicant) => {
        if (_.isString(otherApplicant?.applicant)) {
          return getOtherApplicantsPersonalData(otherApplicant, $axios)
        }
        return null
      })
      .filter(Boolean)
  )
  data.otherApplicants = otherApplicants
}

const cleanData = async (data, deleteKeys) => {
  if (typeof data != 'object') return
  if (!data) return // null object

  for (const key in data) {
    if (deleteKeys.includes(key)) {
      delete data[key]
    } else {
      cleanData(data[key], deleteKeys)
    }
  }
}

const checkTwoObjectDiffExists = async (obj1, obj2) => {
  await cleanData(obj1, [
    'updatedAt',
    '_id',
    'createdAt',
    'uploadedAt',
    'hasLoanDetails',
  ])
  await cleanData(obj2, [
    'updatedAt',
    '_id',
    'createdAt',
    'uploadedAt',
    'hasLoanDetails',
  ])

  return !_.isEqual(obj1, obj2)
}
var documentRequestSource
export default {
  async patchApplication(
    { commit, state },
    { id, applicationId, shouldSendReqestedDocMail }
  ) {
    const vrgPayload = JSON.parse(JSON.stringify(state.application))
    try {
      if (!_.isString(vrgPayload.primaryApplicantData.primaryApplicant))
        vrgPayload.primaryApplicantData.primaryApplicant =
          vrgPayload.primaryApplicantData.primaryApplicant._id

      if (vrgPayload.otherApplicants && vrgPayload.otherApplicants.length > 0) {
        if (!_.isString(vrgPayload.otherApplicants[0].applicant))
          vrgPayload.otherApplicants[0].applicant =
            vrgPayload.otherApplicants[0].applicant._id
      }

      if (!vrgPayload?.status) vrgPayload.status = APPLICATION_STATUS_STARTED

      // will remove loanDetails object when loan details is not set
      deleteLoanObject(vrgPayload)

      // const subUrl = submissionType ? `?submissionType=${submissionType}` : ''
      // const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}${subUrl}`

      const subUrl = shouldSendReqestedDocMail
        ? `?shouldSendReqestedDocMail=${shouldSendReqestedDocMail}`
        : ''
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}${subUrl}`
      const requests = await this.$axios.patch(requestUrl, vrgPayload)
      const { data } = requests
      const userRequestUrl = `/user/${data.primaryApplicantData.primaryApplicant}`
      const userResponse = await this.$axios.get(userRequestUrl)
      data.primaryApplicantData.primaryApplicant = userResponse.data

      if (data.otherApplicants.length > 0) {
        data.otherApplicants = await Promise.all(
          data.otherApplicants.map((otherApplicant) =>
            getOtherApplicantsPersonalData(otherApplicant, this.$axios)
          )
        )
      }

      if (_.isString(data?.tradeIn?.vehicle)) {
        const tradeInVehicleUrl = `/vehicles/${data?.tradeIn?.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(data, tradeInVehicleResponse.data)
      }

      let vehicleObj = null
      if (_.isString(data?.vehicle)) {
        const vehicleUrl = `/vehicles/${data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(data, vehicleResponse)
      } else {
        vehicleObj = {
          vIN: data.vehicle?.vIN || '',
          registrationPlate: data.vehicle?.registrationPlate || '',
          customerNote: data.vehicle?.customerNote || '',
        }
        _.assign(data.vehicle, vehicleObj)
      }
      SetApplicantIdType(data)
      LoanDetailsreasonStatus(data)
      areAllCreditCommitmentsUptoDate(data)
      setLoanDetailsObj(data)
      if (!data.loanDetails.settlement) {
        data.loanDetails.settlement = {
          purchasePrice: data.purchasePrice,
          tradeInAmount:
            data.tradeIn?.assetType === APPLICATION_TRADE_IN_VEHICLE
              ? data.tradeIn?.tradeInAmount
              : '',
          depositAmount: data.vehicle?.financeDepositAmount
            ? data.vehicle?.financeDepositAmount
            : '',
          balloonPct: data.balloonPct,
          loanTermYears: data.loanTermYears,
          balloonAmount: data.balloonAmount,
          monthlyRepayment: '',
          baseRate: '',
          customerRate: '',
          accountKeepingFee: '',
          lenderEstablishmentFee: '',
          partnerFinanceReferralFee: '',
          ppsrFee: '',
          brokerFee: '',
          lendingCommision: '',
          warranty: {
            provider: '',
            product: '',
            wholesale: '',
            retail: '',
            commission: '',
          },
        }
      } else {
        if (data?.loanDetails?.settlement?.warranty?.provider) {
          const warrantyUrl = `/accounts/${data.loanDetails.settlement.warranty.provider}`
          const warrantyUrlResponse = await this.$axios.get(warrantyUrl)
          if (warrantyUrlResponse) {
            data.loanDetails.settlement.warranty.providerData =
              warrantyUrlResponse.data
          }
        }
      }
      // eslint-disable-next-line no-undef
      if (data?.primaryApplicantData?.expenses) {
        const expenses = data.primaryApplicantData.expenses
        data.primaryApplicantData.expenses = updateExpenses(
          expenses,
          data.applicationType
        )
      }
      if (
        Array.isArray(data?.otherApplicants) &&
        data.otherApplicants.length > 0
      ) {
        const expenses = data.otherApplicants[0].expenses
        data.otherApplicants[0].expenses = updateExpenses(
          expenses,
          data.applicationType
        )
      }
      if (!data.loanDetails?.settlement?.insurance) {
        data.loanDetails.settlement.insurance = {
          provider: '',
          policyInfo: '',
          premium: '',
          commission: '',
        }
      } else {
        if (data.loanDetails?.settlement?.insurance?.provider) {
          const insuranceUrl = `/accounts/${data.loanDetails.settlement.insurance.provider}`
          const insuranceUrlResponse = await this.$axios.get(insuranceUrl)

          if (insuranceUrlResponse) {
            data.loanDetails.settlement.insurance.providerData =
              insuranceUrlResponse.data
          }
        }
      }
      setBusinessDataObj(data)
      if (data.loanDetails?.lender) {
        const accountUrl = `/accounts/${data.loanDetails.lender}`
        const accountResponse = await this.$axios.get(accountUrl)
        if (accountResponse) {
          data.loanDetails.lenderData = accountResponse.data
        }
      }
      const requestQuoteUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/quotes`
      const requestesQuotes = await this.$axios.get(requestQuoteUrl)
      data.quotesData = requestesQuotes.data
      if (data?.vehicle?.account === '') {
        delete data.vehicle.account
      }
      if (data?.vehicle?.yearDescription === '') {
        delete data.vehicle.yearDescription
      }
      if (data?.vehicle?.assetDetail === '') {
        delete data.vehicle.assetDetail
      }
      if (data?.vehicle?.financeDepositAmount === '') {
        data.vehicle.financeDepositAmount = 0
      }

      const isDiffExists = await checkTwoObjectDiffExists(
        JSON.parse(JSON.stringify(data)),
        JSON.parse(JSON.stringify(state.cloneApplication))
      )

      if (isDiffExists) {
        $nuxt.$emit('close-loader')

        const applicationMatchesFormData = {
          vRGId: { vehicleRequestGroup: id },
          vehicle: { ...data.vehicle },
          application: { ...data },
        }

        // Removed await as we are not waiting for matches response to come hence block us
        fetch(`${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches`, {
          method: 'POST',
          body: JSON.stringify(applicationMatchesFormData),
          headers: {
            'Content-type': 'application/json',
          },
        })

        commit('setMatchesGeneratedFalse')
        commit('setlenderMatchesSettings', {})

        commit('setNewCloneApplication', { ...data })
      }

      commit('setNewApplication', data)
    } catch (error) {
      commit('setOtherApplicantData', vrgPayload.otherApplicants)
      throw error
    }
  },

  async patchVRGApplication({ commit }, { id, status, applicationId }) {
    const vRGResponse = await this.$axios.get(`/vehicle-request-groups/${id}`)
    if (vRGResponse) {
      const { data } = vRGResponse
      const getLinkIndex = data.links.findIndex((link) => {
        return (
          link.subdocumentId === applicationId &&
          link.subdocumentPath === 'finance.applications'
        )
      })

      const requestUrl1 = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const request1 = await this.$axios.get(requestUrl1)
      data.finance.application = request1.data

      const requestUrl = `/vehicle-request-groups/${id}`
      if (status && getLinkIndex != -1) {
        data.links[getLinkIndex].status = status
      }
      if (
        status === APPLICATION_LINK_STATUS_MARK_AS_REVIEWED &&
        getLinkIndex != -1
      ) {
        data.links[getLinkIndex].applicationReviewedAt =
          new Date().toISOString()
      }
      const requests = await this.$axios.patch(requestUrl, data)
      commit('setLinks', requests?.data?.links)
    }
  },
  async fetchApplications({ commit, getters }, { id, applicationId }) {
    try {
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const requests = await this.$axios.get(requestUrl)
      const { data } = requests

      if (_.isString(data.primaryApplicantData.primaryApplicant)) {
        const userRequestUrl = `/user/${data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        data.primaryApplicantData.primaryApplicant = userResponse.data
      }

      if (_.isString(data?.tradeIn?.vehicle)) {
        const vehicleRequestUrl = `/vehicles/${data?.tradeIn?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleRequestUrl)
        data.tradeIn = vehicleResponse.data
      }
      if (data?.otherApplicants?.length) {
        data.otherApplicants = await Promise.all(
          data.otherApplicants.map((otherApplicant) =>
            getOtherApplicantsPersonalData(otherApplicant, this.$axios)
          )
        )
      }

      // eslint-disable-next-line array-callback-return
      data.primaryApplicantData?.employments?.map((employement) => {
        if (!employement.address) {
          employement.address = {
            country: '',
            address: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postcode: '',
          }
        }

        if (
          employement?.address?.addressLine1 &&
          !employement?.address?.country
        ) {
        }
      })

      data.primaryApplicantData.addresses.forEach((address) => {
        if (address.addressLine1 && !address.country) {
          address.country = LABEL_AUSTRALIA
        }
        if (!address.livingSituation) {
          address.livingSituation = data?.primaryApplicantData?.livingSituation
            ? data.primaryApplicantData.livingSituation
            : LIVING_SITUATION_OWN_HOME_WITH_MORTGAGE
        }
        if (address.areYouRentingThroughAgent === undefined) {
          address.areYouRentingThroughAgent = data?.primaryApplicantData
            ?.areYouRentingThroughAgent
            ? data?.primaryApplicantData?.areYouRentingThroughAgent
            : true
        }
      })
      if (
        data?.businessData?.billingAddress &&
        data?.businessData?.billingAddress?.areYouRentingThroughAgent ===
          undefined
      ) {
        data.businessData.billingAddress.areYouRentingThroughAgent = true
      }
      data?.otherApplicants.forEach((element) => {
        if (
          !_.isString(element.applicant) &&
          !element.applicant.mailingAddress
        ) {
          element.applicant.mailingAddress = {
            country: '',
            createdAt: null,
            isCurrent: Boolean,
            postcode: null,
            state: '',
            updatedAt: null,
          }
        }
      })
      data.primaryApplicantData?.employments?.map((emp) => {
        if (!emp.hasOwnProperty('startDate')) {
          emp.startDate = sub(new Date(), {
            years: emp.atYears,
            months: emp.atAdditionalMonths,
          })
        }
      })
      // eslint-disable-next-line array-callback-return
      data.primaryApplicantData?.employments?.map((emp, index) => {
        if (!emp.startDate) {
          if (emp.isCurrent) {
            emp.startDate = sub(new Date(), {
              years: emp.atYears,
              months: emp.atAdditionalMonths,
            })
          } else {
            if (data.primaryApplicantData.employments.length > 1 && index > 0) {
              emp.startDate = sub(
                new Date(
                  data.primaryApplicantData.employments[index - 1].startDate
                ),
                {
                  years: emp.atYears,
                  months: emp.atAdditionalMonths,
                }
              )
            }
          }
        }
      })
      // eslint-disable-next-line array-callback-return
      data.primaryApplicantData?.liabilities?.map((liability) => {
        liability.installmentFrequency =
          liability.installmentFrequency || 'Monthly'
        liability.installmentAmount = liability.installmentAmount || 0
        liability.outstandingAmount = liability.outstandingAmount || 0
        liability.creditLimit = liability.creditLimit || 0
      })
      // eslint-disable-next-line array-callback-return
      data.primaryApplicantData.assets.map((assets) => {
        if (!assets.amount) {
          assets.amount = 0
        }
      })
      /**
       * In main application we have a only `numberOfDependants` field.
       * So, map default dependantsAge for portal.
       */
      if (data.primaryApplicantData.numberOfDependants) {
        if (
          Array.isArray(data.primaryApplicantData.dependants) &&
          data.primaryApplicantData.dependants.length === 0
        ) {
          const dependantsAge = []
          for (
            let i = 0;
            i < data.primaryApplicantData.numberOfDependants;
            i++
          ) {
            dependantsAge.push({ age: 0 })
          }
          data.primaryApplicantData.dependants = dependantsAge
        }
      }
      SetApplicantIdType(data)
      let vehicleObj = null
      if (_.isString(data?.vehicle)) {
        const vehicleUrl = `/vehicles/${data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)

        abstractVehicleData(data, vehicleResponse)
      } else {
        vehicleObj = {
          vIN: data.vehicle?.vIN || '',
          registrationPlate: data?.vehicle?.registrationPlate || '',
          customerNote: data?.vehicle?.customerNote || '',
        }
        _.assign(data?.vehicle, vehicleObj)
      }

      // eslint-disable-next-line no-undef
      if (data?.primaryApplicantData?.expenses) {
        const expenses = data.primaryApplicantData.expenses
        data.primaryApplicantData.expenses = updateExpenses(
          expenses,
          data.applicationType
        )
      }

      if (
        Array.isArray(data?.otherApplicants) &&
        data.otherApplicants.length > 0
      ) {
        const expenses = data.otherApplicants[0].expenses
        data.otherApplicants[0].expenses = updateExpenses(
          expenses,
          data.applicationType
        )
      }

      data.hasLoanDetails = data.loanDetails ? true : false

      if (data?.status === APPLICATION_STATUS_STARTED) {
        if (data?.loanDetails?.submission?.submittedAt) {
          data.status = APPLICATION_STATUS_SUBMITTED_TO_LENDER
          data.statusChangedAt = new Date().toISOString()
        }
      }

      if (!data.purposeOfObtainingIncreasingOrRefinanceCredit) {
        data.purposeOfObtainingIncreasingOrRefinanceCredit = null
      }
      if (!data.isCreditRequiredForAdditionalCosts) {
        data.isCreditRequiredForAdditionalCosts = false
      }
      if (!data.isAwareOfFinancingCosts) {
        data.isAwareOfFinancingCosts = false
      }

      if (data.loanDetails?.lender) {
        const accountUrl = `/accounts/${data.loanDetails.lender}`
        const accountResponse = await this.$axios.get(accountUrl)
        if (accountResponse) {
          data.loanDetails.lenderData = accountResponse.data
        }
      }
      LoanDetailsreasonStatus(data)
      areAllCreditCommitmentsUptoDate(data)
      setLoanDetailsObj(data)
      if (!data.loanDetails.settlement) {
        data.loanDetails.settlement = {
          purchasePrice: data.purchasePrice,
          tradeInAmount:
            data?.tradeIn?.assetType === APPLICATION_TRADE_IN_VEHICLE
              ? data?.tradeIn?.tradeInAmount
              : '',
          depositAmount: data?.vehicle?.financeDepositAmount
            ? data?.vehicle?.financeDepositAmount
            : '',
          balloonPct: data.balloonPct,
          loanTermYears: data.loanTermYears,
          balloonAmount: data.balloonAmount,
          monthlyRepayment: '',
          baseRate: '',
          customerRate: '',
          accountKeepingFee: '',
          lenderEstablishmentFee: '',
          partnerFinanceReferralFee: '',
          ppsrFee: '',
          brokerFee: '',
          lendingCommision: '',
          warranty: {
            provider: '',
            product: '',
            wholesale: '',
            retail: '',
            commission: '',
          },
        }
      } else {
        if (data?.loanDetails?.settlement?.warranty?.provider) {
          const warrantyUrl = `/accounts/${data.loanDetails.settlement.warranty.provider}`
          const warrantyUrlResponse = await this.$axios.get(warrantyUrl)
          if (warrantyUrlResponse) {
            data.loanDetails.settlement.warranty.providerData =
              warrantyUrlResponse.data
          }
        }
      }

      if (!data.loanDetails.settlement.insurance) {
        data.loanDetails.settlement.insurance = {
          provider: '',
          policyInfo: '',
          premium: '',
          commission: '',
        }
      } else {
        if (data.loanDetails.settlement.insurance.provider) {
          const insuranceUrl = `/accounts/${data.loanDetails.settlement.insurance.provider}`
          const insuranceUrlResponse = await this.$axios.get(insuranceUrl)

          if (insuranceUrlResponse) {
            data.loanDetails.settlement.insurance.providerData =
              insuranceUrlResponse.data
          }
        }
      }

      if (!data.primaryApplicantData.partnerNetIncomeFrequency) {
        data.primaryApplicantData.partnerNetIncomeFrequency =
          INCOME_EXPENSE_FREQUENCY_MONTHLY
      }
      const requestQuoteUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/quotes`
      const requestesQuotes = await this.$axios.get(requestQuoteUrl)
      data.quotesData = requestesQuotes.data

      setBusinessDataObj(data)
      if (!data?.vehicle) {
        data.vehicle = {}
        data.vehicle.customerNote = data.vehicle?.customerNote || ''
        data.vehicle.vIN = data.vehicle?.vIN || ''
        data.vehicle.registrationPlate = data.vehicle?.registrationPlate || ''
        data.vehicle.purchaseSource = data.vehicle?.purchaseSource || ''
        data.vehicle.condition = data.vehicle?.condition || ''
        data.vehicle.makeCode = data.vehicle?.makeCode || ''
        data.vehicle.makeDescription = data.vehicle?.makeDescription || ''
        data.vehicle.code = data.vehicle?.code || ''
        data.vehicle.codeDescription = data.vehicle?.codeDescription || ''
        data.vehicle.assetDetail = data.vehicle?.assetDetail || ''
        data.vehicle.familyCode = data.vehicle?.familyCode || ''
        data.vehicle.familyDescription = data.vehicle?.familyDescription || ''
        data.vehicle.assetType = data.vehicle?.assetType || 'Vehicle'
        data.vehicle.account = data.vehicle?.account || ''
        data.vehicle.financeDepositAmount =
          data.vehicle?.financeDepositAmount || ''
        data.vehicle.type = data.vehicle?.type || 'Asset'
        data.vehicle.use = data.vehicle?.use || ''
        data.vehicle.year = data.vehicle?.year || ''
        data.vehicle.yearDescription = data.vehicle?.yearDescription || ''
      }
      commit('setNewCloneApplication', JSON.parse(JSON.stringify(data)))
      commit('setNewApplication', data)
      if (getters.getApplication.applicationType === LABEL_FOR_COMMERCIAL) {
        $nuxt.$emit('update-component-data')
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw error
    } finally {
      commit('setAppLoaded', true)
    }
  },

  setApplicationField({ commit }, payload) {
    commit('setApplicationField', payload)
  },

  async setApplicationStatus(
    { commit, dispatch },
    { event, id, applicationId }
  ) {
    try {
      commit('setApplicationStatus', event)
      await dispatch('patchApplication', {
        id,
        applicationId,
        shouldSendReqestedDocMail: true,
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
      await dispatch('fetchApplications', { id, applicationId })
    }
  },

  async setApplicationReasonTypes(
    { commit, dispatch },
    { reasonTypes, id, applicationId }
  ) {
    if (
      reasonTypes.loanDetails?.approval?.outcomeOfAnApplication ===
      LABEL_WITHDRAWN
    ) {
      reasonTypes.loanDetails.approval.doesApprovedAsSubmitted = null
      reasonTypes.loanDetails.approval.withdrawnAt = new Date().toISOString()
    }
    if (
      reasonTypes.loanDetails?.approval?.outcomeOfAnApplication ===
      LABEL_DECLINED
    ) {
      reasonTypes.loanDetails.approval.doesApprovedAsSubmitted = null
      reasonTypes.loanDetails.approval.declinedAt = new Date().toISOString()
      reasonTypes.status = APPLICATION_STATUS_DECLINED
    }
    if (reasonTypes?.withdrawnReasonType || reasonTypes?.withdrawnReason) {
      reasonTypes.status = APPLICATION_STATUS_WITHDRAWN
    } else {
      reasonTypes.status = APPLICATION_STATUS_DECLINED
    }
    reasonTypes.statusChangedAt = new Date().toISOString()
    commit('setApplicationReasonTypes', reasonTypes)
    await dispatch('patchApplication', { id, applicationId })
  },

  async updateApplication(
    { commit, dispatch, getters },
    { id, applicationId, primaryApplicant, vrgStatus, isSecondary, index }
  ) {
    try {
      commit('setAppLoaded', false)
      if (isSecondary) {
        if (primaryApplicant.userData) {
          primaryApplicant.applicant = { ...primaryApplicant.userData }
        }
      } else {
        if (primaryApplicant.userData) {
          primaryApplicant.primaryApplicant = { ...primaryApplicant.userData }
        }
      }
      const userRequestUrl = `/user/${
        isSecondary
          ? primaryApplicant.applicant._id
          : primaryApplicant.primaryApplicant._id
      }`
      await this.$axios.patch(
        userRequestUrl,
        isSecondary
          ? primaryApplicant.applicant
          : primaryApplicant.primaryApplicant
      )

      if (isSecondary) {
        commit('setSecondaryApplicationByIndex', {
          index,
          application: primaryApplicant,
        })
      } else {
        commit('setPrimaryApplication', primaryApplicant)
      }
      if (vrgStatus) {
        commit('setApplicationStatus', vrgStatus)
      }

      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      let errorMessage = axiosErrorMessageExtractor(error)
      if (
        errorMessage === ERROR_MESSAGE_FOR_USER_MOBILE_NUMBER_ALREADY_EXISTS
      ) {
        errorMessage = `${ERROR_MESSAGE_FOR_ENTERED_MOBILE_NO} <b>${
          getters.getApplication.applicationType === LABEL_CONSUMER
            ? primaryApplicant?.primaryApplicant?.mobile
            : primaryApplicant?.userData?.mobile
        }</b> ${ERROR_MESSAGE_FOR_USER_INFO_ALREADY_EXISTS}`
      } else if (errorMessage === ERROR_MESSAGE_FOR_USER_EMAIL_ALREADY_EXISTS) {
        errorMessage = `${ERROR_MESSAGE_FOR_ENTERED_EMAIL} <b>${
          getters.getApplication.applicationType === LABEL_CONSUMER
            ? primaryApplicant?.primaryApplicant?.email
            : primaryApplicant?.userData?.email
        }</b> ${ERROR_MESSAGE_FOR_USER_INFO_ALREADY_EXISTS}`
      } else {
        return errorMessage
      }
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
        dangerouslyUseHTMLString: true,
        message: errorMessage,
      })
      await dispatch('fetchApplications', { id, applicationId })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateApplicationEmployments(
    { commit, dispatch },
    { id, applicationId, employments }
  ) {
    try {
      commit('setEmployments', employments)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateSecondaryApplicationEmployments(
    { commit, dispatch },
    { id, applicationId, employments, activeApplicantIndex }
  ) {
    try {
      commit('setSecondaryEmployments', { employments, activeApplicantIndex })
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async checkMissingFields(
    { state },

    { loanDetails, id, applicationId, status }
  ) {
    try {
      const vrgPayload = loanDetails
        ? loanDetails
        : JSON.parse(JSON.stringify(state.application))

      vrgPayload.status = status

      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/validate-required-fields`
      await this.$axios.patch(requestUrl, vrgPayload)
    } catch (error) {
      throw error
    }
  },
  async switchApplication(
    { commit, dispatch, state },
    { id, applicationId, keepSecondaryApplicantData, applicationData }
  ) {
    try {
      commit('setAppLoaded', false)

      if (applicationData?.businessData) {
        commit('setBusinessDetails', applicationData.businessData)
      }

      const appliactionType =
        applicationData.applicationType == LABEL_CONSUMER
          ? LABEL_FOR_COMMERCIAL
          : LABEL_CONSUMER
      const loanType =
        applicationData.applicationType == LABEL_CONSUMER
          ? LABEL_COMMERCIAL_CAR_LOAN
          : LABEL_CONSUMER_CAR_LOAN
      commit('setApplicationType', appliactionType)
      commit('setApplicationLoanType', loanType)

      commit('setPrimaryApplication', applicationData.primaryApplicantData)
      if (keepSecondaryApplicantData) {
        commit('setOtherApplicantData', applicationData.otherApplicants)
      } else {
        commit('setOtherApplicantData', [])
      }

      await dispatch('patchApplication', { id, applicationId })
      // generate matches again
      let matchesVrgPayload = JSON.parse(JSON.stringify(state.application))
      if (matchesVrgPayload?.vehicle?.latestValuation?.retailPrice) {
        matchesVrgPayload.vehicle.latestValuation.retailPrice = parseInt(
          this.vehicleValuation
        )
      }

      if (matchesVrgPayload?.tradeIn) {
        matchesVrgPayload.tradeIn.tradeInAmount = this.tradeInAmount
      } else {
        matchesVrgPayload = {
          ...matchesVrgPayload,
          tradeIn: { tradeInAmount: this.tradeInAmount },
        }
      }

      const applicationMatchesFormData = {
        ...matchesVrgPayload,
        vRGId: { vehicleRequestGroup: id },
        vehicle: { ...matchesVrgPayload.vehicle },
        application: { ...matchesVrgPayload },
      }

      await dispatch('generateLenderMatches', {
        id,
        applicationId,
        applicationMatchesFormData,
      })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async deleteSecondaryApplicant(
    { commit },
    { id, applicationId, applicantId }
  ) {
    try {
      const deleteSecondaryUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}`
      const deleteResponse = await this.$axios.delete(deleteSecondaryUrl)
      const { data } = deleteResponse

      if (data) {
        const userRequestUrl = `/user/${data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        data.primaryApplicantData.primaryApplicant = userResponse.data

        if (data.otherApplicants.length) {
          await getOtherApplicantData(data, this.$axios)
        }

        let vehicleObj = null
        if (_.isString(data?.vehicle)) {
          const vehicleUrl = `/vehicles/${data?.vehicle}`
          const vehicleResponse = await this.$axios.get(vehicleUrl)
          abstractVehicleData(data, vehicleResponse)
        } else {
          vehicleObj = {
            vIN: data?.vehicle?.vIN || '',
            registrationPlate: data?.vehicle?.registrationPlate || '',
            customerNote: data?.vehicle?.customerNote || '',
          }
          _.assign(data.vehicle, vehicleObj)
        }
        SetApplicantIdType(data)
        LoanDetailsreasonStatus(data)
        setLoanDetailsObj(data)
        if (
          data.loanDetails.approval &&
          [LABEL_APPROVED].includes(
            data.loanDetails.approval.outcomeOfAnApplication
          )
        ) {
          if (!data.loanDetails.settlement) {
            data.loanDetails.settlement = {
              ...loanDetailsCommonObj,
              warranty: {
                provider: '',
                product: '',
                wholesale: '',
                retail: '',
                commission: '',
              },
            }
          } else {
            if (data?.loanDetails?.settlement?.warranty?.provider) {
              const warrantyUrl = `/accounts/${data.loanDetails.settlement.warranty.provider}`
              const warrantyUrlResponse = await this.$axios.get(warrantyUrl)
              if (warrantyUrlResponse) {
                data.loanDetails.settlement.warranty.providerData =
                  warrantyUrlResponse.data
              }
            }
          }

          if (!data.loanDetails.settlement.insurance) {
            data.loanDetails.settlement.insurance = {
              provider: '',
              policyInfo: '',
              premium: '',
              commission: '',
            }
          } else {
            if (data.loanDetails.settlement.insurance.provider) {
              const insuranceUrl = `/accounts/${data.loanDetails.settlement.insurance.provider}`
              const insuranceUrlResponse = await this.$axios.get(insuranceUrl)

              if (insuranceUrlResponse) {
                data.loanDetails.settlement.insurance.providerData =
                  insuranceUrlResponse.data
              }
            }
          }
        }
        commit('setOtherApplicantData', data.otherApplicants)
        setBusinessDataObj(data)
        commit('setNewApplication', data)
      }
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      this.$notify.success({
        title: 'Success',
        message: 'Applicant deleted successfully',
      })

      commit('setAppLoaded', true)
    }
  },

  async updateApplicationExpenses(
    { commit, dispatch },
    { id, applicationId, expenses }
  ) {
    try {
      commit('setExpenses', expenses)
      commit('setExpenseDisclaimer', expenses.primaryData.expensesDisclaimer)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateOtherApplicantData(
    { commit, dispatch },
    { id, applicationId, otherApplicant }
  ) {
    try {
      const requestUrl = `/user/add/customer`
      const latestApplicantIndex =
        otherApplicant.length > 0 ? otherApplicant.length - 1 : 0
      const otherApplicantPayloadData = {
        mobile: otherApplicant[latestApplicantIndex].applicant.mobile,
        email: otherApplicant[latestApplicantIndex].applicant.email,
      }

      const requests = await this.$axios.post(
        requestUrl,
        otherApplicantPayloadData
      )
      const {
        data: { user: userData },
      } = requests

      const otherUserData = otherApplicant[latestApplicantIndex].applicant
      if (!_.isString(otherUserData) && !otherUserData.mailingAddress) {
        otherUserData.mailingAddress = {
          country: '',
          createdAt: null,
          isCurrent: Boolean,
          postcode: null,
          state: '',
          updatedAt: null,
        }
      }

      otherApplicant[latestApplicantIndex].applicant = userData._id
      const dataSecondaryApplicant = {
        ...userData,
        ...otherUserData,
      }

      const otherUserRequestUrl = `/user/${userData._id}`
      await this.$axios.patch(otherUserRequestUrl, dataSecondaryApplicant)
      commit('removeSpousalData')
      commit('setOtherApplicantData', otherApplicant)
      commit('copyPrimaryApplicantAddressData')
      await dispatch('patchApplication', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      otherApplicant.pop()
      commit('setOtherApplicantData', otherApplicant)

      this.$notify.error({
        title: 'Error',
        message: error?.response?.data?.error ?? '',
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateApplicationIncome(
    { commit, dispatch },

    { id, applicationId, income }
  ) {
    try {
      commit('setIncomes', income)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',

        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  // eslint-disable-next-line require-await
  async updateApplicationBlankExpense({ commit }, { expenses }) {
    commit('setDefaultExpense', expenses)
  },

  // eslint-disable-next-line require-await
  async updateApplicationBlankSecondaryExpense(
    { commit },
    { secondaryExpense }
  ) {
    commit('setDefaultSecondaryExpense', secondaryExpense)
  },

  // eslint-disable-next-line require-await
  async updateSelectedDocuments(
    { commit, dispatch, getters },

    { id, applicationId, selectedDocuments }
  ) {
    try {
      const applicationStatus = getters.getApplicationStatus
      shouldChangeTheApplicationStatusToAwaitingDocs({
        selectedDocuments,
        applicationStatus,
      }) && commit('setApplicationStatus', APPLICATION_STATUS_AWAITING_DOCS)
      commit('setSelectedDocuments', selectedDocuments)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  // eslint-disable-next-line require-await
  async updateOtherSelectedDocuments(
    { commit, dispatch, getters },

    { id, applicationId, selectedDocuments, secondaryIndex }
  ) {
    try {
      const applicationStatus = getters.getApplicationStatus
      shouldChangeTheApplicationStatusToAwaitingDocs({
        selectedDocuments,
        applicationStatus,
      }) && commit('setApplicationStatus', APPLICATION_STATUS_AWAITING_DOCS)
      commit('setOtherSelectedDocuments', { selectedDocuments, secondaryIndex })
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',

        message: 'Application updated successfully',
      })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  // eslint-disable-next-line require-await
  async updateSharedSelectedDocuments(
    { commit, dispatch, getters },

    { id, applicationId, selectedDocuments }
  ) {
    try {
      const applicationStatus = getters.getApplicationStatus
      shouldChangeTheApplicationStatusToAwaitingDocs({
        selectedDocuments,
        applicationStatus,
      }) && commit('setApplicationStatus', APPLICATION_STATUS_AWAITING_DOCS)
      commit('setSharedSelectedDocuments', selectedDocuments)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },

  async removeSelectedDocument(
    { commit, dispatch },

    { id, applicationId, documentType }
  ) {
    try {
      commit('updateSelectedDocument', documentType)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',

        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async setDocumentsData(
    { commit, dispatch },
    { id, applicationId, documentData, secondaryIndex, type, selectedDocuments }
  ) {
    try {
      if (type === LABEL_DOCUMENT_PRIMARY) {
        commit('setPrimaryApplicantDocuments', documentData)
        commit('setSelectedDocuments', selectedDocuments)
      } else if (type === LABEL_SHARED || type === LABEL_DOCUMENT_SETTLEMENT) {
        commit('setSharedDocuments', documentData)
        commit('setSharedSelectedDocuments', selectedDocuments)
      } else if (type === LABEL_DOCUMENT_SECONDARY) {
        commit('setSecondaryApplicantDocuments', {
          documentData,
          secondaryIndex,
        })
        commit('setOtherSelectedDocuments', {
          selectedDocuments,
          secondaryIndex,
        })
      }

      await dispatch('patchApplication', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async removeSelectedBankStatementDocument(
    { commit, dispatch },
    { id, applicationId, documentType }
  ) {
    try {
      commit('updateSelectedBankStatementDocument', documentType)
      await dispatch('patchApplication', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async removeOtherSelectedBankStatementDocument(
    { commit, dispatch },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { id, applicationId, documentNum, documentType }
  ) {
    try {
      commit('removeOtherSelectedBankStatementDocument', {
        index: documentNum,
        documentNum,
        documentType,
      })
      await dispatch('patchApplication', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async removeOtherSelectedDocument(
    { commit, dispatch },

    { id, applicationId, documentNum, secondaryIndex, documentType }
  ) {
    try {
      commit('updateOtherSelectedDocument', {
        index: secondaryIndex,
        documentNum,
        documentType,
      })
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',

        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async removeSharedSelectedDocument(
    { commit, dispatch },

    { id, applicationId, documentType }
  ) {
    try {
      commit('updateSharedSelectedDocument', documentType)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',

        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },

  async updateDocuments(
    { commit, dispatch },

    { id, applicationId, documents }
  ) {
    try {
      commit('setDocumentData', documents)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'File uploaded successfully!',
      })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    }
  },

  async removeDocument(
    { commit, dispatch },

    { id, applicationId, url }
  ) {
    try {
      commit('removeDocumentData', url)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'File removed successfully!',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateOtherDocuments(
    { commit, dispatch },

    { id, applicationId, documents, secondaryIndex }
  ) {
    try {
      commit('setOtherDocumentData', { documents, index: secondaryIndex })
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'File uploaded successfully!',
      })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    }
  },

  async removeOtherDocument(
    { commit, dispatch },

    { id, applicationId, url, secondaryIndex }
  ) {
    try {
      commit('removeOtherDocumentData', { url, index: secondaryIndex })
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'File removed successfully!',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',

        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateSharedDocuments(
    { commit, dispatch },

    { id, applicationId, documents }
  ) {
    try {
      commit('setSharedDocumentData', documents)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'File uploaded successfully!',
      })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    }
  },

  async removeSharedDocument(
    { commit, dispatch },

    { id, applicationId, url }
  ) {
    try {
      commit('removeSharedDocumentData', url)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'File removed successfully!',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',

        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async importApplication(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { dispatch },
    { id, applicationId, selectedApplicationId }
  ) {
    try {
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/${selectedApplicationId}/import-application-data`
      await this.$axios.patch(requestUrl)
      await dispatch('fetchApplications', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async emailAndAddSubmit(
    { commit, state },

    { id, applicationId, applicant }
  ) {
    try {
      const vrgPayload = JSON.parse(JSON.stringify(state.application))
      vrgPayload.primaryApplicantData.primaryApplicant =
        vrgPayload.primaryApplicantData.primaryApplicant._id

      if (vrgPayload.otherApplicants && vrgPayload.otherApplicants.length > 0) {
        vrgPayload.otherApplicants[0].applicant =
          vrgPayload.otherApplicants[0].applicant._id
      }

      deleteLoanObject(vrgPayload)
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicant}/emails/requested-docs-names/`
      const requests = await this.$axios.post(requestUrl, vrgPayload)
      const { data } = requests

      const userRequestUrl = `/user/${data.primaryApplicantData.primaryApplicant}`
      const userResponse = await this.$axios.get(userRequestUrl)
      data.primaryApplicantData.primaryApplicant = userResponse.data

      if (data.otherApplicants.length > 0) {
        const otherUserRequestUrl = `/user/${data.otherApplicants[0].applicant}`
        const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
        data.otherApplicants[0].applicant = otherUserResponse.data
      }

      let vehicleObj = null
      if (_.isString(data.vehicle)) {
        const vehicleUrl = `/vehicles/${data.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(data, vehicleResponse)
      } else {
        vehicleObj = {
          vIN: data.vehicle?.vIN || '',
          registrationPlate: data.vehicle?.registrationPlate || '',
          customerNote: data.vehicle?.customerNote || '',
        }
        _.assign(data.vehicle, vehicleObj)
      }
      LoanDetailsreasonStatus(data)
      setLoanDetailsObj(data)
      if (!data.loanDetails.settlement) {
        data.loanDetails.settlement = {
          purchasePrice: data?.purchasePrice,
          tradeInAmount:
            data.tradeIn?.assetType === APPLICATION_TRADE_IN_VEHICLE
              ? data.tradeIn?.tradeInAmount
              : '',
          depositAmount: data.vehicle?.financeDepositAmount
            ? data.vehicle?.financeDepositAmount
            : '',
          balloonPct: data.balloonPct,
          loanTermYears: data.loanTermYears,
          balloonAmount: data.balloonAmount,
          monthlyRepayment: '',
          baseRate: '',
          customerRate: '',
          accountKeepingFee: '',
          lenderEstablishmentFee: '',
          partnerFinanceReferralFee: '',
          ppsrFee: '',
          brokerFee: '',
          lendingCommision: '',
          warranty: {
            provider: '',
            product: '',
            wholesale: '',
            retail: '',
            commission: '',
          },
        }
      } else {
        if (data?.loanDetails?.settlement?.warranty?.provider) {
          const warrantyUrl = `/accounts/${data.loanDetails.settlement.warranty.provider}`
          const warrantyUrlResponse = await this.$axios.get(warrantyUrl)
          if (warrantyUrlResponse) {
            data.loanDetails.settlement.warranty.providerData =
              warrantyUrlResponse.data
          }
        }
      }

      if (!data.loanDetails.settlement.insurance) {
        data.loanDetails.settlement.insurance = {
          provider: '',
          policyInfo: '',
          premium: '',
          commission: '',
        }
      } else {
        if (data.loanDetails.settlement.insurance.provider) {
          const insuranceUrl = `/accounts/${data.loanDetails.settlement.insurance.provider}`
          const insuranceUrlResponse = await this.$axios.get(insuranceUrl)

          if (insuranceUrlResponse) {
            data.loanDetails.settlement.insurance.providerData =
              insuranceUrlResponse.data
          }
        }
      }
      setBusinessDataObj(data)
      commit('setNewApplication', data)

      this.$notify.success({
        title: 'Success',
        message: 'Email successfully!',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },

  // eslint-disable-next-line require-await
  async updateSelectedDocumentsWithEmail(
    { commit, dispatch, state, getters },

    { id, applicationId, selectedDocuments, applicant, isFromSettlementTab }
  ) {
    try {
      const applicationStatus = getters.getApplicationStatus
      shouldChangeTheApplicationStatusToAwaitingDocs({
        selectedDocuments,
        applicationStatus,
      }) && commit('setApplicationStatus', APPLICATION_STATUS_AWAITING_DOCS)
      commit('setSelectedDocuments', selectedDocuments)
      await dispatch('patchApplication', {
        id,
        applicationId,
      })

      try {
        const vrgPayload1 = JSON.parse(JSON.stringify(state.application))
        vrgPayload1.primaryApplicantData.primaryApplicant =
          vrgPayload1.primaryApplicantData.primaryApplicant._id

        if (
          vrgPayload1.otherApplicants &&
          vrgPayload1.otherApplicants.length > 0
        ) {
          vrgPayload1.otherApplicants[0].applicant =
            vrgPayload1.otherApplicants[0].applicant._id
        }

        let emailReqUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicant}/emails/requested-docs-names`
        if (isFromSettlementTab) {
          emailReqUrl =
            emailReqUrl + `?documentFor=${LABEL_DOCUMENT_SETTLEMENT}`
        }
        await this.$axios.post(emailReqUrl, vrgPayload1)

        this.$notify.success({
          title: 'Success',
          message: 'Email has been sent successfully',
        })
      } catch (error) {
        const err =
          error.response?.data.error || axiosErrorMessageExtractor(error)
        this.$notify.error({
          title: 'Error',
          message: err,
        })
      } finally {
        commit('setAppLoaded', true)
      }
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      await dispatch('fetchApplications', { id, applicationId })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  // eslint-disable-next-line require-await
  async updateOtherSelectedDocumentsWithEmail(
    { commit, state, dispatch, getters },

    {
      id,
      applicationId,
      selectedDocuments,
      applicant,
      secondaryIndex,
      isFromSettlementTab,
    }
  ) {
    try {
      const applicationStatus = getters.getApplicationStatus
      shouldChangeTheApplicationStatusToAwaitingDocs({
        selectedDocuments,
        applicationStatus,
      }) && commit('setApplicationStatus', APPLICATION_STATUS_AWAITING_DOCS)
      commit('setOtherSelectedDocuments', { selectedDocuments, secondaryIndex })
      await dispatch('patchApplication', {
        id,
        applicationId,
      })

      try {
        const vrgPayload1 = JSON.parse(JSON.stringify(state.application))
        vrgPayload1.primaryApplicantData.primaryApplicant =
          vrgPayload1.primaryApplicantData.primaryApplicant._id

        if (
          vrgPayload1.otherApplicants &&
          vrgPayload1.otherApplicants.length > 0
        ) {
          vrgPayload1.otherApplicants[0].applicant =
            vrgPayload1.otherApplicants[0].applicant._id
        }

        let emailReqUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicant}/emails/requested-docs-names`
        if (isFromSettlementTab) {
          emailReqUrl =
            emailReqUrl + `?documentFor=${LABEL_DOCUMENT_SETTLEMENT}`
        }
        await this.$axios.post(emailReqUrl, vrgPayload1)
      } catch (error) {
        console.log('err ', error)
        this.$notify.error({
          title: 'Error',
          message: axiosErrorMessageExtractor(error),
        })
      } finally {
        commit('setAppLoaded', true)
      }
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      await dispatch('fetchApplications', { id, applicationId })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  // eslint-disable-next-line require-await
  async updateSharedSelectedDocumentsWithEmail(
    { commit, getters, dispatch },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { id, applicationId, selectedDocuments, applicant, isFromSettlementTab }
  ) {
    try {
      const applicationStatus = getters.getApplicationStatus
      shouldChangeTheApplicationStatusToAwaitingDocs({
        selectedDocuments,
        applicationStatus,
      }) && commit('setApplicationStatus', APPLICATION_STATUS_AWAITING_DOCS)
      commit('setSharedSelectedDocuments', selectedDocuments)
      await dispatch('patchApplication', {
        id,
        applicationId,
      })

      try {
        let emailReqUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicant}/emails/requested-docs-names?isShared=true`
        if (isFromSettlementTab) {
          emailReqUrl =
            emailReqUrl + `&documentFor=${LABEL_DOCUMENT_SETTLEMENT}`
        }
        await this.$axios.post(emailReqUrl)
      } catch (error) {
        this.$notify.error({
          title: 'Error',
          message: axiosErrorMessageExtractor(error),
        })
      } finally {
        commit('setAppLoaded', true)
      }
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      await dispatch('fetchApplications', { id, applicationId })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },

  // eslint-disable-next-line require-await
  async requestbusinessdocumentsBankStatement(
    { commit, dispatch, getters },
    { id, applicationId, applicantId, requestedMonths }
  ) {
    try {
      const bankStatementRequestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/request-bank-statement?numMonths=${requestedMonths}`
      await this.$axios.post(bankStatementRequestUrl)
      const currentDate = new Date().toISOString()
      const selectedDocuments = {
        purpose: 'Bank statement (secure)',
        createdAt: currentDate,
        requestedAt: currentDate,
        updatedAt: currentDate,
      }
      commit('setSharedSelectedDocument', selectedDocuments)

      const applicationStatus = getters?.getSharedApplicantData?.status
      shouldChangeTheApplicationStatusToAwaitingDocsForBankStatement(
        applicationStatus
      ) && commit('setApplicationStatus', APPLICATION_STATUS_AWAITING_DOCS)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  // eslint-disable-next-line require-await
  async requestPrimaryBankStatement(
    { commit, dispatch, getters },

    { id, applicationId, applicantId, requestedMonths }
  ) {
    try {
      const bankStatementRequestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/request-bank-statement?numMonths=${requestedMonths}`
      await this.$axios.post(bankStatementRequestUrl)
      commit('setPrimaryBankStatement')
      const currentDate = new Date().toISOString()
      const selectedDocuments = {
        purpose: 'Bank statement (secure)',
        createdAt: currentDate,
        requestedAt: currentDate,
        updatedAt: currentDate,
      }

      commit('setSelectedDocument', selectedDocuments)
      const applicationStatus = getters?.getSharedApplicantData?.status

      shouldChangeTheApplicationStatusToAwaitingDocsForBankStatement(
        applicationStatus
      ) && commit('setApplicationStatus', APPLICATION_STATUS_AWAITING_DOCS)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },

  // eslint-disable-next-line require-await
  async requestSecondaryBankStatement(
    { commit, dispatch, getters },

    { id, applicationId, applicantId, requestedMonths, secondaryIndex }
  ) {
    try {
      const bankStatementRequestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/request-bank-statement?numMonths=${requestedMonths}`
      await this.$axios.post(bankStatementRequestUrl)
      commit('setSecondaryBankStatement', secondaryIndex)
      const currentDate = new Date().toISOString()
      const selectedDocuments = {
        purpose: 'Bank statement (secure)',
        createdAt: currentDate,
        requestedAt: currentDate,
        updatedAt: currentDate,
      }
      commit('setOtherSelectedDocument', { selectedDocuments, secondaryIndex })
      const applicationStatus = getters?.getSharedApplicantData?.status
      shouldChangeTheApplicationStatusToAwaitingDocsForBankStatement(
        applicationStatus
      ) && commit('setApplicationStatus', APPLICATION_STATUS_AWAITING_DOCS)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      const err =
        error.response?.data.error || axiosErrorMessageExtractor(error)
      this.$notify.error({
        title: 'Error',
        message: err,
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  // eslint-disable-next-line require-await
  async updateRequirementLoanDetails(
    { commit, dispatch },

    { id, applicationId, loanData }
  ) {
    try {
      if (loanData?.vehicle) {
        const loanVehicleId = _.isObject(loanData?.vehicle)
          ? loanData?.vehicle?._id
          : loanData?.vehicle
        const getVehicleUrl = `/vehicles/${loanVehicleId}`
        const vehicleRes = await this.$axios.get(getVehicleUrl)
        Object.assign(vehicleRes.data, {
          financeDepositAmount: loanData?.vehicle?.financeDepositAmount,
        })

        const updateVehicleUrl = `/vehicles/${loanData?.vehicle?._id}`
        await this.$axios.patch(updateVehicleUrl, vehicleRes?.data)
      }

      if (loanData?.tradeIn) {
        // trade in amount update in tradein vehicle
        const tradeInVehicleId = _.isObject(loanData?.tradeIn)
          ? loanData?.tradeIn?._id
          : _.isString(loanData?.tradeIn?.vehicle)
          ? loanData?.tradeIn?.vehicle
          : loanData?.tradeIn

        if (tradeInVehicleId) {
          const getTradeInVehicleUrl = `/vehicles/${tradeInVehicleId}`
          const tradeVehicleRes = await this.$axios.get(getTradeInVehicleUrl)

          Object.assign(tradeVehicleRes.data, {
            tradeInAmount: parseInt(loanData?.tradeIn?.tradeInAmount),
          })

          const updateTradeVehicleUrl = `/vehicles/${tradeInVehicleId}`
          await this.$axios.patch(updateTradeVehicleUrl, tradeVehicleRes?.data)
        }
      }

      LoanDetailsreasonStatus(loanData)
      setLoanDetailsObj(loanData)
      if (!loanData?.loanDetails?.settlement) {
        loanData.loanDetails.settlement = {
          purchasePrice: loanData.purchasePrice,
          tradeInAmount:
            loanData?.vehicle?.assetType === APPLICATION_TRADE_IN_VEHICLE
              ? loanData?.vehicle?.tradeInAmount
              : '',
          depositAmount: loanData?.vehicle?.financeDepositAmount
            ? loanData?.vehicle?.financeDepositAmount
            : '',
          balloonPct: loanData.balloonPct,
          loanTermYears: loanData.loanTermYears,
          balloonAmount: loanData.balloonAmount,
          monthlyRepayment: '',
          baseRate: '',
          customerRate: '',
          accountKeepingFee: '',
          lenderEstablishmentFee: '',
          partnerFinanceReferralFee: '',
          ppsrFee: '',
          brokerFee: '',
          lendingCommision: '',
          warranty: {
            provider: '',
            product: '',
            wholesale: '',
            retail: '',
            commission: '',
          },
        }
      } else {
        if (loanData?.loanDetails?.settlement?.warranty?.provider) {
          const warrantyUrl = `/accounts/${loanData.loanDetails.settlement.warranty.provider}`
          const warrantyUrlResponse = await this.$axios.get(warrantyUrl)
          if (warrantyUrlResponse) {
            loanData.loanDetails.settlement.warranty.providerData =
              warrantyUrlResponse.data
          }
        }
      }

      if (!loanData?.loanDetails?.settlement?.insurance) {
        loanData.loanDetails.settlement.insurance = {
          provider: '',
          policyInfo: '',
          premium: '',
          commission: '',
        }
      } else {
        if (loanData?.loanDetails?.settlement?.insurance?.provider) {
          const insuranceUrl = `/accounts/${loanData.loanDetails.settlement.insurance.provider}`
          const insuranceUrlResponse = await this.$axios.get(insuranceUrl)

          if (insuranceUrlResponse) {
            loanData.loanDetails.settlement.insurance.providerData =
              insuranceUrlResponse.data
          }
        }
      }
      setBusinessDataObj(loanData)
      commit('setNewApplication', loanData)

      await dispatch('patchApplication', { id, applicationId })
      commit('updateVehicles', loanData.vehicle)

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: error?.response?.data?.error ?? '',
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async getAllMakes() {
    try {
      const makesUrl = `/vehicles-meta-data/makes`
      const makesRes = await this.$axios.get(makesUrl)

      return makesRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
      return { data: [] }
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getAllModels({}, { makeCode }) {
    try {
      const modelsUrl = `vehicles-meta-data/models?makeCode=${makeCode}`
      const modelsRes = await this.$axios.get(modelsUrl)

      return modelsRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getAllYears({}, { makeCode, familyCode }) {
    try {
      const yearsUrl = `vehicles-meta-data/years?makeCode=${makeCode}&familyCode=${familyCode}`
      const yearsRes = await this.$axios.get(yearsUrl)

      return yearsRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getAllModelTypes({}, { makeCode, familyCode, year }) {
    try {
      const modelTypesUrl = `vehicles-meta-data/vehicles?makeCode=${makeCode}&familyCode=${familyCode}&year=${year}`
      const modelTypesRes = await this.$axios.get(modelTypesUrl)

      return modelTypesRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line require-await
  async updateRequirementAssetDetails(
    { commit, dispatch },
    { assetData, id, applicationId }
  ) {
    try {
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      let requestVerb = 'post'
      let requestUrl = '/vehicles'

      if (assetData?.vehicle?._id !== undefined) {
        requestUrl = `/vehicles/${assetData?.vehicle?._id}`
        requestVerb = 'patch'
      }

      const requests = await this.$axios[requestVerb](
        requestUrl,
        assetData?.vehicle
      )
      const { data } = requests
      commit('updateVehicles', data)

      await dispatch('patchApplication', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: error?.response?.data?.error ?? '',
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateRequirements(
    { commit, dispatch },

    {
      id,
      applicationId,
      purposeOfObtainingIncreasingOrRefinanceCredit,
      loanType,
      monthlyRepaymentBudget,
      customerExpectedLoanFeatures,
      isCreditRequiredForAdditionalCosts,
      isAwareOfFinancingCosts,
    }
  ) {
    try {
      commit(
        'updateApplicationPurpose',
        purposeOfObtainingIncreasingOrRefinanceCredit
      )
      commit('updateLoanType', loanType)
      commit('updateApplicationMonthlyRepayment', monthlyRepaymentBudget)
      commit(
        'updateApplicationExpectedLoanFeatures',
        customerExpectedLoanFeatures
      )
      commit(
        'updateIsCreditRequiredForAdditionalCosts',
        isCreditRequiredForAdditionalCosts
      )
      commit('updateIsAwareOfFinancingCosts', isAwareOfFinancingCosts)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: error?.response?.data?.error ?? '',
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateSignedUrl(
    { commit },
    {
      signedUrl = '/assets/signed-url',
      name,
      type,
      size,
      user,
      vrgId,
      applicationId,
      isShared = false,
    }
  ) {
    try {
      let signedRes
      if (isShared) {
        signedRes = await this.$axios.post(signedUrl, {
          name,
          type,
          size,
          vrgId,
          applicationId,
        })
      } else {
        signedRes = await this.$axios.post(signedUrl, {
          name,
          type,
          size,
          vrgId,
          user,
        })
      }

      return signedRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },

  async updateAssetsLiabilities(
    { commit, dispatch },
    {
      id,
      applicationId,
      primaryAssetsData,
      secondaryAssetsData,
      primaryLiabilitiesData,
      sceondaryLiabilitiesData,
      areAllCreditcommitmentData,
    }
  ) {
    try {
      const {
        assets: bindedPrimaryAssets,
        liabilities: bindedPrimaryLiabilities,
      } = bindLiabilityWithAssetIfHas(primaryAssetsData, primaryLiabilitiesData)
      const {
        assets: bindedSecondaryAssets,
        liabilities: bindedSecondaryLiabilities,
      } = bindLiabilityWithAssetIfHas(
        secondaryAssetsData,
        sceondaryLiabilitiesData
      )

      commit('setPrimaryAssets', bindedPrimaryAssets)
      commit('setSecondaryAssets', bindedSecondaryAssets)
      commit('setPrimaryLiabilities', bindedPrimaryLiabilities)
      commit('setSecondaryLiabilities', bindedSecondaryLiabilities)
      commit('setCreditCommitments', areAllCreditcommitmentData)

      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  /* eslint-disable */
  async runCreditReport(
    { commit, dispatch },
    { id, applicationId, applicantId }
  ) {
    try {
      const creditEnquiryUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/credit-enquiry`
      const enquiryResponse = await this.$axios.get(creditEnquiryUrl)
      commit('setCreditEnquiries', {
        payload: enquiryResponse.data,
        applicantId,
      })
      if (!enquiryResponse.data.isError) {
        this.$notify({
          title: 'Success',
          message: CREDIT_CHECK_SUCCESS_MESSAGE,
          type: 'success',
          duration: 5000,
        })
      }
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      await dispatch('fetchApplications', { id, applicationId })
      return enquiryResponse
    } catch (error) {
      throw error
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async runRefetchCreditEnquiryFile(
    { commit, dispatch },
    { id, applicationId, applicantId }
  ) {
    try {
      const refetchFileUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/refetch-credit-enquiry-file`
      const enquiryResponse = await this.$axios.get(refetchFileUrl)
      commit('setRefetchFile', {
        payload: enquiryResponse.data,
        applicantId,
      })
      if (!enquiryResponse.data.isError) {
        this.$notify({
          title: 'Success',
          message: REFETCH_FILE_CHECK_SUCCESS_MESSAGE,
          type: 'success',
          duration: 5000,
        })
        await dispatch('fetchApplications', { id, applicationId })
      }
      return enquiryResponse
    } catch (error) {
      throw error
    } finally {
      commit('setAppLoaded', true)
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getAllModels({}, { makeCode }) {
    try {
      const modelsUrl = `vehicles-meta-data/models?makeCode=${makeCode}`
      const modelsRes = await this.$axios.get(modelsUrl)

      return modelsRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getAllYears({}, { makeCode, familyCode }) {
    try {
      const yearsUrl = `vehicles-meta-data/years?makeCode=${makeCode}&familyCode=${familyCode}`
      const yearsRes = await this.$axios.get(yearsUrl)

      return yearsRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getAllModelTypes({}, { makeCode, familyCode, year }) {
    try {
      const modelTypesUrl = `vehicles-meta-data/vehicles?makeCode=${makeCode}&familyCode=${familyCode}&year=${year}`
      const modelTypesRes = await this.$axios.get(modelTypesUrl)

      return modelTypesRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  /* eslint-enable */
  async updateSecondaryApplication(
    { commit, dispatch, getters },
    { id, applicationId, applicant, vrgStatus, index }
  ) {
    try {
      commit('setAppLoaded', false)

      const userRequestUrl = `/user/${applicant.applicant._id}`
      await this.$axios.patch(userRequestUrl, applicant.applicant)

      commit('setSecondaryApplicationByIndex', {
        application: applicant,
        index,
      })
      if (vrgStatus) commit('setApplicationStatus', vrgStatus)

      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      let errorMessage = axiosErrorMessageExtractor(error)
      if (
        errorMessage === ERROR_MESSAGE_FOR_USER_MOBILE_NUMBER_ALREADY_EXISTS
      ) {
        errorMessage = `${ERROR_MESSAGE_FOR_ENTERED_MOBILE_NO} <b>${applicant?.applicant?.mobile}</b> ${ERROR_MESSAGE_FOR_USER_INFO_ALREADY_EXISTS}`
      } else if (errorMessage === ERROR_MESSAGE_FOR_USER_EMAIL_ALREADY_EXISTS) {
        errorMessage = `${ERROR_MESSAGE_FOR_ENTERED_EMAIL} <b>${applicant?.applicant?.email}</b> ${ERROR_MESSAGE_FOR_USER_INFO_ALREADY_EXISTS}`
      }
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
        dangerouslyUseHTMLString: true,
        message: errorMessage,
      })
      await dispatch('fetchApplications', { id, applicationId })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  /* eslint-enable */
  resetVehicleData({ commit }) {
    const resetFields = {
      code: '',
      codeDescription: '',
      familyCode: '',
      familyDescription: '',
      makeCode: '',
      makeDescription: '',
      year: null,
      yearDescription: null,
    }
    commit('resetVehicle', resetFields)
  },

  async updateNotes({ commit, dispatch }, { notes, id, applicationId }) {
    try {
      const notesData = {}
      notesData.description = notes
      notesData.updatedAt = new Date()
      commit('setNotesDetails', notesData)
      await dispatch('patchApplication', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateVehicle({ commit, dispatch }, { vehicle, id, applicationId }) {
    try {
      let requestVerb = 'post'
      let requestUrl = '/vehicles'

      if (vehicle?._id !== undefined) {
        requestUrl = `/vehicles/${vehicle?._id}`
        requestVerb = 'patch'
      }

      const requests = await this.$axios[requestVerb](requestUrl, vehicle)
      const { data } = requests
      commit('updateVehicles', data)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Successfully updated the vehicle',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async eSignRequestEmail({}, { id, applicationId, applicantId }) {
    try {
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/esign-requests`
      await this.$axios.post(requestUrl)

      this.$notify.success({
        title: 'Success',
        message: 'Email & SMS is sent successfully!',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message:
          error?.response?.data?.error ?? axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getBusinessSearchABN({}, { business }) {
    try {
      if (business !== '') {
        const searchQueryUrl = `businesses/search?query=${business}&excludeCancelled=true`
        const searchResult = await this.$axios.get(searchQueryUrl)

        return searchResult
      }
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: error?.response?.data?.error ?? '',
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getBusinessStructure({}, { abn }) {
    try {
      const searchQueryUrl = `businesses/search-by-abn?abn=${abn}`
      const searchResult = await this.$axios.get(searchQueryUrl)

      return searchResult
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: error?.response?.data?.error ?? '',
      })
    }
  },
  setDefaultState({ commit }) {
    try {
      commit('setNewReferralApplication', DEFAULT_VEHICLE_GROUP_OBJECT)
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async getVRGApplication({ commit }, { id }) {
    try {
      const vRGResponse = await this.$axios.get(`/vehicle-request-groups/${id}`)
      if (vRGResponse) {
        if (vRGResponse.data?.owner) {
          const owner = await this.$axios.get('/user/sales-force-user', {
            params: {
              sFUserId: vRGResponse.data?.owner,
            },
          })
          if (owner.data?.user?._id) {
            commit('setOwner', {
              ...owner.data.user,
              doesOwnerPresentInPortal: true,
            })
          } else {
            commit('setOwner', {
              doesOwnerPresentInPortal: false,
            })
          }
        } else {
          commit('clearOwner')
        }
        commit('setLeadSource', vRGResponse.data.leadSource)
        commit('setLeadSourceSystem', vRGResponse.data.leadSourceSystem)
        commit('setWebAppJourney', vRGResponse.data.webAppJourney)
        commit('setLinks', vRGResponse.data.links)
        commit('setSfLead', vRGResponse.data.sFLead)
        commit('setSFOpportunity', vRGResponse.data?.sFOpportunity || {})
        commit('setPartnerId', vRGResponse.data?.partnerId || '')
        commit('setReferrerAccount', vRGResponse.data?.referrerAccount || '')
        commit(
          'setInitialLeadFunnel',
          vRGResponse.data?.initialLeadFunnel || ''
        )
      }
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getCommercialAccounts(
    { commit },
    {
      isLender = false,
      isInsurer = false,
      isWarrantyProvider = false,
      skip = 0,
      exclusionList = [],
    }
  ) {
    try {
      const requestAccount = `/accounts`
      const requestAccountResponse = await this.$axios.get(requestAccount, {
        params: {
          ...(isLender && { isLender }),
          ...(isInsurer && { isInsurer }),
          ...(isWarrantyProvider && { isWarrantyProvider }),
          skip,
        },
      })
      const { data } = requestAccountResponse
      let accountList = data
      if (exclusionList.length) {
        accountList = data?.filter(
          ({ businessName }) => !exclusionList.includes(businessName)
        )
      }
      commit('setAccounts', accountList)
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getCloneApplication({ commit }, { id, applicationId }) {
    try {
      const requestClone = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/clone`
      const requestCloneResponse = await this.$axios.get(requestClone)
      const { data } = requestCloneResponse
      LoanDetailsreasonStatus(data)
      setLoanDetailsObj(data)
      if (!data.loanDetails.settlement) {
        data.loanDetails.settlement = {
          purchasePrice: data?.purchasePrice,
          tradeInAmount:
            data.vehicle?.assetType === APPLICATION_TRADE_IN_VEHICLE
              ? data.vehicle?.tradeInAmount
              : '',
          depositAmount: data?.vehicle?.financeDepositAmount
            ? data?.vehicle?.financeDepositAmount
            : '',
          balloonPct: data?.balloonPct,
          loanTermYears: data?.loanTermYears,
          balloonAmount: data?.balloonAmount,
          monthlyRepayment: '',
          baseRate: '',
          customerRate: '',
          accountKeepingFee: '',
          lenderEstablishmentFee: '',
          partnerFinanceReferralFee: '',
          ppsrFee: '',
          brokerFee: '',
          lendingCommision: '',
          warranty: {
            provider: '',
            product: '',
            wholesale: '',
            retail: '',
            commission: '',
          },
        }
      } else {
        if (data?.loanDetails?.settlement?.warranty?.provider) {
          const warrantyUrl = `/accounts/${data.loanDetails.settlement.warranty.provider}`
          const warrantyUrlResponse = await this.$axios.get(warrantyUrl)
          if (warrantyUrlResponse) {
            data.loanDetails.settlement.warranty.providerData =
              warrantyUrlResponse.data
          }
        }
      }

      if (!data.loanDetails.settlement.insurance) {
        data.loanDetails.settlement.insurance = {
          provider: '',
          policyInfo: '',
          premium: '',
          commission: '',
        }
      } else {
        if (data.loanDetails?.settlement?.insurance?.provider) {
          const insuranceUrl = `/accounts/${data.loanDetails.settlement.insurance.provider}`
          const insuranceUrlResponse = await this.$axios.get(insuranceUrl)

          if (insuranceUrlResponse) {
            data.loanDetails.settlement.insurance.providerData =
              insuranceUrlResponse.data
          }
        }
      }

      let vehicleObj = null
      if (_.isString(data?.vehicle)) {
        const vehicleUrl = `/vehicles/${data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(data, vehicleResponse)
      } else {
        vehicleObj = {
          vIN: data.vehicle?.vIN || '',
          registrationPlate: data.vehicle?.registrationPlate || '',
          customerNote: data.vehicle?.customerNote || '',
        }
        _.assign(data.vehicle, vehicleObj)
      }

      SetApplicantIdType(data)
      setBusinessDataObj(data)
      commit('setNewApplication', data)
      return true
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async downloadApplication(
    { commit, state },
    { id, applicationId, shouldIncludeComplianceQuestions }
  ) {
    try {
      let generatePrelimaryAssessmentUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/generate-preliminary-assessment`
      generatePrelimaryAssessmentUrl += `?shouldIncludeComplianceQuestions=${!!shouldIncludeComplianceQuestions}`
      const generatedPDF = await this.$axios.post(
        generatePrelimaryAssessmentUrl
      )
      let applicationName = getPdfNameOfPreliminaryAssessment(state.application)
      if (!shouldIncludeComplianceQuestions && generatedPDF?.data?.pdf) {
        let base64Data = generatedPDF.data.pdf
        downloadPdfFromBase64({ base64Data, pdfName: applicationName })
      }
      let successMsg = 'Application downloaded successfully'
      this.$notify.success({
        title: 'Success',
        message: successMsg,
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async generateNewAppLink({ commit, state }, { id, applicationId }) {
    try {
      const generatePrelimaryAssessmentUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/generate-application-link`
      const generated = await this.$axios.post(generatePrelimaryAssessmentUrl)
      const links = generated.data.links
      if (links.length) {
        commit('setLinks', links)
      }
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateLoanSubmission(
    { commit, state },
    { id, applicationId, loanDetails, shouldIncludeComplianceQuestions }
  ) {
    try {
      if (
        loanDetails?.vehicle &&
        !_.isString(loanDetails?.vehicle) &&
        loanDetails?.loanType !== LOAN_TYPE_PERSONAL_LOAN
      ) {
        const vehicleRequestUrl = `/vehicles/${loanDetails?.vehicle?._id}`
        const vehicleResponse = await this.$axios.patch(
          vehicleRequestUrl,
          loanDetails?.vehicle
        )
        commit('updateVehicles', vehicleResponse.data)
      }
      setBusinessDataObj(loanDetails)
      commit('setNewApplication', loanDetails)
      const vrgPayload = JSON.parse(JSON.stringify(state.application))

      if (!_.isString(vrgPayload.primaryApplicantData.primaryApplicant))
        vrgPayload.primaryApplicantData.primaryApplicant =
          vrgPayload.primaryApplicantData.primaryApplicant._id

      if (vrgPayload.otherApplicants && vrgPayload.otherApplicants.length > 0) {
        if (!_.isString(vrgPayload.otherApplicants[0].applicant))
          vrgPayload.otherApplicants[0].applicant =
            vrgPayload.otherApplicants[0].applicant._id
      }

      vrgPayload.loanDetails.submission.submittedAt = ''
      if (!vrgPayload.loanDetails.creditProposalDisclosureSentAt) {
        vrgPayload.loanDetails.creditProposalDisclosureSentAt = ''
      }
      if (!vrgPayload.loanDetails.creditProposalDisclosureAt) {
        vrgPayload.loanDetails.creditProposalDisclosureAt = ''
      }
      vrgPayload.status =
        vrgPayload.applicationType === LABEL_FOR_COMMERCIAL
          ? APPLICATION_STATUS_READY_FOR_SUBMISSION
          : APPLICATION_STATUS_STARTED
      vrgPayload.statusChangedAt = new Date().toISOString()

      if (vrgPayload) {
        vrgPayload.withdrawnReasonType = ''
        vrgPayload.withdrawnReason = ''
        vrgPayload.declinedReason = ''
        vrgPayload.declinedReasonType = ''
      }

      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const requests = await this.$axios.patch(requestUrl, vrgPayload)
      const { data } = requests

      if (_.isString(data?.primaryApplicantData?.primaryApplicant)) {
        const userRequestUrl = `/user/${data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        data.primaryApplicantData.primaryApplicant = userResponse.data
      }

      if (data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(data, this.$axios)
      } else {
        if (_.isString(data?.otherApplicants[0]?.applicant)) {
          const otherUserRequestUrl = `/user/${data.otherApplicants[0].applicant}`
          const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
          data.otherApplicants[0].applicant = otherUserResponse.data
        }
      }

      let vehicleObj = null
      if (data?.vehicle && _.isString(data?.vehicle)) {
        const vehicleUrl = `/vehicles/${data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(data, vehicleResponse)
      } else {
        if (data?.loanType !== LOAN_TYPE_PERSONAL_LOAN) {
          vehicleObj = {
            vIN: data?.vehicle?.vIN || '',
            registrationPlate: data?.vehicle?.registrationPlate || '',
            customerNote: data?.vehicle?.customerNote || '',
          }
          _.assign(data?.vehicle, vehicleObj)
        }
      }
      SetApplicantIdType(data)
      setBusinessDataObj(data)
      if (loanDetails.quotesData) {
        data.quotesData = loanDetails.quotesData
      }
      if (loanDetails?.loanDetails?.lenderData) {
        data.loanDetails.lenderData = loanDetails.loanDetails.lenderData
      }

      commit('setNewApplication', data)
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const generatePrelimaryAssessmentUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/generate-preliminary-assessment?shouldIncludeComplianceQuestions=${shouldIncludeComplianceQuestions}`
      const generatedPDF = await this.$axios.post(
        generatePrelimaryAssessmentUrl
      )
      let applicationName = getPdfNameOfPreliminaryAssessment(state.application)
      if (!shouldIncludeComplianceQuestions && generatedPDF?.data?.pdf) {
        let base64Data = generatedPDF.data.pdf
        downloadPdfFromBase64({ base64Data, pdfName: applicationName })
      }

      if (
        shouldIncludeComplianceQuestions &&
        data.applicationType !== LABEL_FOR_COMMERCIAL
      ) {
        const uuID = Math.random().toString().slice(2, 11)
        sessionStorage.setItem('uniquePatchRequestID', uuID)
        const generateProposalDisclousureUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/generate-proposal-disclosure`
        await this.$axios.post(generateProposalDisclousureUrl)
      }

      const requestUrlForVrgGet = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const responseForVrgGet = await this.$axios.get(requestUrlForVrgGet)

      if (
        _.isString(responseForVrgGet.data.primaryApplicantData.primaryApplicant)
      ) {
        const userRequestUrl = `/user/${responseForVrgGet.data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        responseForVrgGet.data.primaryApplicantData.primaryApplicant =
          userResponse.data
      }
      if (responseForVrgGet?.data?.otherApplicants.length > 1) {
        await getOtherApplicantData(responseForVrgGet.data, this.$axios)
      } else {
        if (
          responseForVrgGet.data.otherApplicants &&
          responseForVrgGet.data.otherApplicants[0] &&
          _.isString(responseForVrgGet.data.otherApplicants[0].applicant)
        ) {
          if (responseForVrgGet.data.otherApplicants.length > 0) {
            const otherUserRequestUrl = `/user/${responseForVrgGet.data.otherApplicants[0].applicant}`
            const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
            responseForVrgGet.data.otherApplicants[0].applicant =
              otherUserResponse.data
          }
        }
      }

      if (
        responseForVrgGet?.data?.vehicle &&
        _.isString(responseForVrgGet?.data?.vehicle)
      ) {
        const vehicleUrl = `/vehicles/${responseForVrgGet?.data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        responseForVrgGet.data.vehicle = vehicleResponse.data
      }

      if (
        responseForVrgGet?.data?.tradeIn?.vehicle &&
        _.isString(responseForVrgGet?.data?.tradeIn?.vehicle)
      ) {
        const tradeInVehicleUrl = `/vehicles/${responseForVrgGet.data.tradeIn.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(
          responseForVrgGet.data,
          tradeInVehicleResponse.data
        )
      }

      if (responseForVrgGet.data.loanDetails?.lender) {
        const accountUrl = `/accounts/${responseForVrgGet.data.loanDetails.lender}`
        const accountResponse = await this.$axios.get(accountUrl)
        if (accountResponse) {
          responseForVrgGet.data.loanDetails.lenderData = accountResponse.data
        }
      }

      if (loanDetails.quotesData) {
        responseForVrgGet.data.quotesData = loanDetails.quotesData
      }

      const isDiffExists = await checkTwoObjectDiffExists(
        JSON.parse(JSON.stringify(data)),
        JSON.parse(JSON.stringify(state.cloneApplication))
      )

      if (isDiffExists) {
        $nuxt.$emit('close-loader')
        const uuID = Math.random().toString().slice(2, 11)
        sessionStorage.setItem('uniquePatchRequestID', uuID)
        const applicationMatchesFormData = {
          vRGId: { vehicleRequestGroup: id },
          vehicle: { ...data.vehicle },
          application: { ...data },
        }
        fetch(`${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches`, {
          method: 'POST',
          body: JSON.stringify(applicationMatchesFormData),
          headers: {
            'Content-type': 'application/json',
          },
        })

        commit('setMatchesGeneratedFalse')
        commit('setlenderMatchesSettings', {})

        commit('setNewCloneApplication', data)
      }

      commit('setNewApplication', responseForVrgGet.data)
      let successMsg = !shouldIncludeComplianceQuestions
        ? 'Application saved and downloaded successfully'
        : 'Application updated successfully'
      this.$notify.success({
        title: 'Success',
        message: successMsg,
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async saveLoanSubmission(
    { commit, state },
    { id, applicationId, loanDetails }
  ) {
    try {
      if (!_.isString(loanDetails?.vehicle)) {
        const vehicleRequestUrl = `/vehicles/${loanDetails?.vehicle?._id}`
        const vehicleResponse = await this.$axios.patch(
          vehicleRequestUrl,
          loanDetails?.vehicle
        )
        commit('updateVehicles', vehicleResponse.data)
      }
      setBusinessDataObj(loanDetails)
      commit('setNewApplication', loanDetails)
      const vrgPayload = JSON.parse(JSON.stringify(state.application))

      if (!_.isString(vrgPayload.primaryApplicantData.primaryApplicant))
        vrgPayload.primaryApplicantData.primaryApplicant =
          vrgPayload.primaryApplicantData.primaryApplicant._id

      if (vrgPayload.otherApplicants && vrgPayload.otherApplicants.length > 0) {
        if (!_.isString(vrgPayload.otherApplicants[0].applicant))
          vrgPayload.otherApplicants[0].applicant =
            vrgPayload.otherApplicants[0].applicant._id
      }

      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const requests = await this.$axios.patch(requestUrl, vrgPayload)
      const { data } = requests

      if (_.isString(data.primaryApplicantData.primaryApplicant)) {
        const userRequestUrl = `/user/${data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        data.primaryApplicantData.primaryApplicant = userResponse.data
      }

      if (data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(data, this.$axios)
      } else {
        if (data.otherApplicants.length > 0) {
          if (_.isString(data.otherApplicants[0].applicant)) {
            const otherUserRequestUrl = `/user/${data.otherApplicants[0].applicant}`
            const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
            data.otherApplicants[0].applicant = otherUserResponse.data
          }
        }
      }

      let vehicleObj = null
      if (_.isString(data?.vehicle)) {
        const vehicleUrl = `/vehicles/${data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(data, vehicleResponse)
      } else {
        vehicleObj = {
          vIN: data?.vehicle?.vIN || '',
          registrationPlate: data?.vehicle?.registrationPlate || '',
          customerNote: data?.vehicle?.customerNote || '',
        }
        _.assign(data?.vehicle, vehicleObj)
      }
      SetApplicantIdType(data)
      setBusinessDataObj(data)
      if (loanDetails.quotesData) {
        data.quotesData = loanDetails.quotesData
      }
      if (loanDetails?.loanDetails?.lenderData) {
        data.loanDetails.lenderData = loanDetails.loanDetails.lenderData
      }

      commit('setNewApplication', data)

      const requestUrlForVrgGet = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const responseForVrgGet = await this.$axios.get(requestUrlForVrgGet)

      if (
        _.isString(responseForVrgGet.data.primaryApplicantData.primaryApplicant)
      ) {
        const userRequestUrl = `/user/${responseForVrgGet.data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        responseForVrgGet.data.primaryApplicantData.primaryApplicant =
          userResponse.data
      }

      if (responseForVrgGet?.data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(responseForVrgGet.data, this.$axios)
      } else {
        if (
          responseForVrgGet.data.otherApplicants &&
          responseForVrgGet.data.otherApplicants[0] &&
          _.isString(responseForVrgGet.data.otherApplicants[0].applicant)
        ) {
          if (responseForVrgGet.data.otherApplicants.length > 0) {
            const otherUserRequestUrl = `/user/${responseForVrgGet.data.otherApplicants[0].applicant}`
            const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
            responseForVrgGet.data.otherApplicants[0].applicant =
              otherUserResponse.data
          }
        }
      }

      if (_.isString(responseForVrgGet?.data?.vehicle)) {
        const vehicleUrl = `/vehicles/${responseForVrgGet?.data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        responseForVrgGet.data.vehicle = vehicleResponse.data
      }

      if (_.isString(responseForVrgGet?.data?.tradeIn?.vehicle)) {
        const tradeInVehicleUrl = `/vehicles/${responseForVrgGet.data.tradeIn.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(
          responseForVrgGet.data,
          tradeInVehicleResponse.data
        )
      }

      if (responseForVrgGet.data.loanDetails?.lender) {
        const accountUrl = `/accounts/${responseForVrgGet.data.loanDetails.lender}`
        const accountResponse = await this.$axios.get(accountUrl)
        if (accountResponse) {
          responseForVrgGet.data.loanDetails.lenderData = accountResponse.data
        }
      }
      if (loanDetails.quotesData) {
        responseForVrgGet.data.quotesData = loanDetails.quotesData
      }
      if (loanDetails?.loanDetails?.lenderData) {
        responseForVrgGet.data.loanDetails.lenderData =
          loanDetails.loanDetails.lenderData
      }
      const isDiffExists = await checkTwoObjectDiffExists(
        JSON.parse(JSON.stringify(data)),
        JSON.parse(JSON.stringify(state.cloneApplication))
      )
      if (isDiffExists) {
        $nuxt.$emit('close-loader')

        const applicationMatchesFormData = {
          vRGId: { vehicleRequestGroup: id },
          vehicle: { ...data.vehicle },
          application: { ...data },
        }
        fetch(`${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches`, {
          method: 'POST',
          body: JSON.stringify(applicationMatchesFormData),
          headers: {
            'Content-type': 'application/json',
          },
        })

        commit('setMatchesGeneratedFalse')
        commit('setlenderMatchesSettings', {})

        commit('setNewCloneApplication', data)
      }
      commit('setNewApplication', responseForVrgGet.data)
      let successMsg = 'Application updated successfully'
      this.$notify.success({
        title: 'Success',
        message: successMsg,
      })
    } catch (err) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(err),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateLoanDecision(
    { commit, state },
    { id, applicationId, loanDetails }
  ) {
    try {
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      if (
        loanDetails?.vehicle &&
        !_.isString(loanDetails?.vehicle) &&
        loanDetails?.loanType !== LOAN_TYPE_PERSONAL_LOAN
      ) {
        const vehicleRequestUrl = `/vehicles/${loanDetails?.vehicle?._id}`
        const vehicleResponse = await this.$axios.patch(
          vehicleRequestUrl,
          loanDetails.vehicle
        )
        commit('updateVehicles', vehicleResponse.data)
      }
      commit('setNewApplication', loanDetails)
      const vrgPayload = JSON.parse(JSON.stringify(state.application))

      if (!_.isString(vrgPayload.primaryApplicantData.primaryApplicant))
        vrgPayload.primaryApplicantData.primaryApplicant =
          vrgPayload.primaryApplicantData.primaryApplicant._id

      if (vrgPayload.otherApplicants && vrgPayload.otherApplicants.length > 0) {
        if (!_.isString(vrgPayload.otherApplicants[0].applicant))
          vrgPayload.otherApplicants[0].applicant =
            vrgPayload.otherApplicants[0].applicant._id
      }
      if (
        vrgPayload.loanDetails.approval.outcomeOfAnApplication ===
        LABEL_APPROVED
      ) {
        vrgPayload.loanDetails.approval.approvedAt = new Date().toISOString()
        if (
          vrgPayload.loanDetails.approval.outcomeOfAnApplication ===
          LABEL_APPROVED
        ) {
          vrgPayload.status = APPLICATION_STATUS_APPROVED
          vrgPayload.statusChangedAt = new Date().toISOString()
        }
      }

      if (
        vrgPayload.loanDetails.approval.outcomeOfAnApplication ===
        LABEL_DECLINED
      ) {
        vrgPayload.loanDetails.approval.doesApprovedAsSubmitted = null
        vrgPayload.loanDetails.approval.declinedAt = new Date().toISOString()
        vrgPayload.status = APPLICATION_STATUS_DECLINED
        vrgPayload.statusChangedAt = new Date().toISOString()
      }
      if (
        vrgPayload.loanDetails.approval.outcomeOfAnApplication ===
        LABEL_WITHDRAWN
      ) {
        vrgPayload.loanDetails.approval.doesApprovedAsSubmitted = null
        vrgPayload.loanDetails.approval.withdrawnAt = new Date().toISOString()
        vrgPayload.status = APPLICATION_STATUS_WITHDRAWN
        vrgPayload.statusChangedAt = new Date().toISOString()
      }

      if (
        !vrgPayload?.loanDetails?.settlement?.preSettledAt &&
        !vrgPayload?.loanDetails?.settlement?.settledAt &&
        vrgPayload?.loanDetails?.approval
      ) {
        vrgPayload.loanDetails.settlement = {
          ...vrgPayload.loanDetails.approval,
          purchasePrice: vrgPayload.loanDetails.approval.purchasePrice,
          tradeInAmount: vrgPayload.loanDetails.approval.tradeInAmount,
          depositAmount: vrgPayload.loanDetails.approval.depositAmount,
          balloonPct: vrgPayload.loanDetails.approval.balloonPct,
          loanTermYears: vrgPayload.loanDetails.approval.loanTermYears,
          balloonAmount: vrgPayload.loanDetails.approval.balloonAmount,
          monthlyRepayment: vrgPayload.loanDetails.approval.monthlyRepayment,
          baseRate: vrgPayload.loanDetails.approval.baseRate,
          customerRate: vrgPayload.loanDetails.approval.customerRate,
          accountKeepingFee: vrgPayload.loanDetails.approval.accountKeepingFee,
          lenderEstablishmentFee:
            vrgPayload.loanDetails.approval.lenderEstablishmentFee,
          partnerFiananceReferralFee:
            vrgPayload.loanDetails.approval.partnerFiananceReferralFee,
          ppsrFee: vrgPayload.loanDetails.approval.ppsrFee,
          brokerFee: vrgPayload.loanDetails.approval.brokerFee,
          lendingCommision: vrgPayload.loanDetails.approval.lendingCommision,
          outcomeOfAnApplication: '',
          approvalExpiresAt: '',
          approvalConditions: '',
          withdrawnReasonType: '',
          withdrawnReason: '',
          declinedReasonType: '',
          declinedReason: '',
          doesApprovedAsSubmitted: null,
        }
      }
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const outcomeOfAnApplication =
        vrgPayload.loanDetails.approval.outcomeOfAnApplication
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      if (outcomeOfAnApplication === LABEL_APPROVED) {
        vrgPayload.loanDetails.approval.outcomeOfAnApplication = ''
        const requestsExtraPatch = await this.$axios.patch(
          requestUrl,
          vrgPayload
        )
        const { data } = requestsExtraPatch
        vrgPayload.loanDetails.approval.outcomeOfAnApplication =
          outcomeOfAnApplication
        vrgPayload.loanDetails.approval.partnerFinanceReferralFee =
          data.loanDetails?.approval?.partnerFinanceReferralFee
      }
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const requests = await this.$axios.patch(requestUrl, vrgPayload)
      const { data } = requests

      if (_.isString(data.primaryApplicantData.primaryApplicant)) {
        const userRequestUrl = `/user/${data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        data.primaryApplicantData.primaryApplicant = userResponse.data
      }

      if (data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(data, this.$axios)
      } else {
        if (data.otherApplicants.length > 0) {
          if (_.isString(data.otherApplicants[0].applicant)) {
            const otherUserRequestUrl = `/user/${data.otherApplicants[0].applicant}`
            const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
            data.otherApplicants[0].applicant = otherUserResponse.data
          }
        }
      }

      let vehicleObj = null
      if (data?.vehicle && _.isString(data?.vehicle)) {
        const vehicleUrl = `/vehicles/${data.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(data, vehicleResponse)
      } else {
        if (data?.loanType !== LOAN_TYPE_PERSONAL_LOAN) {
          vehicleObj = {
            vIN: data.vehicle?.vIN || '',
            registrationPlate: data.vehicle?.registrationPlate || '',
            customerNote: data.vehicle?.customerNote || '',
          }
          _.assign(data.vehicle, vehicleObj)
        }
      }

      if (data?.tradeIn?.vehicle && _.isString(data.tradeIn.vehicle)) {
        const tradeInVehicleUrl = `/vehicles/${data.tradeIn.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(data, tradeInVehicleResponse.data)
      }
      SetApplicantIdType(data)
      LoanDetailsreasonStatus(data)
      setLoanDetailsObj(data)
      if (!data.loanDetails.settlement) {
        data.loanDetails.settlement = {
          purchasePrice: data?.purchasePrice,
          tradeInAmount:
            data?.tradeIn?.assetType === APPLICATION_TRADE_IN_VEHICLE
              ? data?.tradeIn?.tradeInAmount
              : '',
          depositAmount: data.vehicle?.financeDepositAmount
            ? data.vehicle?.financeDepositAmount
            : '',
          balloonPct: data.balloonPct,
          loanTermYears: data.loanTermYears,
          balloonAmount: data.balloonAmount,
          monthlyRepayment: '',
          baseRate: '',
          customerRate: '',
          accountKeepingFee: '',
          lenderEstablishmentFee: '',
          partnerFinanceReferralFee: '',
          ppsrFee: '',
          brokerFee: '',
          lendingCommision: '',
          warranty: {
            provider: '',
            product: '',
            wholesale: '',
            retail: '',
            commission: '',
          },
        }
      } else {
        if (data?.loanDetails?.settlement?.warranty?.provider) {
          const warrantyUrl = `/accounts/${data.loanDetails.settlement.warranty.provider}`
          const warrantyUrlResponse = await this.$axios.get(warrantyUrl)
          if (warrantyUrlResponse) {
            data.loanDetails.settlement.warranty.providerData =
              warrantyUrlResponse.data
          }
        }
      }

      if (!data.loanDetails.settlement.insurance) {
        data.loanDetails.settlement.insurance = {
          provider: '',
          policyInfo: '',
          premium: '',
          commission: '',
        }
      } else {
        if (data.loanDetails.settlement.insurance.provider) {
          const insuranceUrl = `/accounts/${data.loanDetails.settlement.insurance.provider}`
          const insuranceUrlResponse = await this.$axios.get(insuranceUrl)

          if (insuranceUrlResponse) {
            data.loanDetails.settlement.insurance.providerData =
              insuranceUrlResponse.data
          }
        }
      }
      setBusinessDataObj(data)
      if (loanDetails.quotesData) {
        data.quotesData = loanDetails.quotesData
      }
      if (loanDetails?.loanDetails?.lenderData) {
        data.loanDetails.lenderData = loanDetails.loanDetails.lenderData
      }

      commit('setNewApplication', data)

      const requestUrlForVrgGet = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const responseForVrgGet = await this.$axios.get(requestUrlForVrgGet)

      if (
        _.isString(responseForVrgGet.data.primaryApplicantData.primaryApplicant)
      ) {
        const userRequestUrl = `/user/${responseForVrgGet.data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        responseForVrgGet.data.primaryApplicantData.primaryApplicant =
          userResponse.data
      }

      if (responseForVrgGet?.data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(responseForVrgGet.data, this.$axios)
      } else {
        if (
          responseForVrgGet.data.otherApplicants &&
          responseForVrgGet.data.otherApplicants[0] &&
          _.isString(responseForVrgGet.data.otherApplicants[0].applicant)
        ) {
          if (responseForVrgGet.data.otherApplicants.length > 0) {
            const otherUserRequestUrl = `/user/${responseForVrgGet.data.otherApplicants[0].applicant}`
            const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
            responseForVrgGet.data.otherApplicants[0].applicant =
              otherUserResponse.data
          }
        }
      }

      if (
        responseForVrgGet?.data?.vehicle &&
        _.isString(responseForVrgGet?.data?.vehicle)
      ) {
        const vehicleUrl = `/vehicles/${responseForVrgGet?.data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        responseForVrgGet.data.vehicle = vehicleResponse.data
      }
      if (!responseForVrgGet.data.loanDetails.approval) {
        responseForVrgGet.data.loanDetails.approval =
          decisionDataBasedOnSubmissionData(responseForVrgGet.data.loanDetails)
      }

      if (
        responseForVrgGet?.data?.tradeIn?.vehicle &&
        _.isString(responseForVrgGet?.data?.tradeIn?.vehicle)
      ) {
        const tradeInVehicleUrl = `/vehicles/${responseForVrgGet.data.tradeIn.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(
          responseForVrgGet.data,
          tradeInVehicleResponse.data
        )
      }

      if (responseForVrgGet.data.loanDetails?.lender) {
        const accountUrl = `/accounts/${responseForVrgGet.data.loanDetails.lender}`
        const accountResponse = await this.$axios.get(accountUrl)
        if (accountResponse) {
          responseForVrgGet.data.loanDetails.lenderData = accountResponse.data
        }
      }

      if (!responseForVrgGet.data.loanDetails.settlement) {
        responseForVrgGet.data.loanDetails.settlement =
          settlementDataBasedOnDecisionData(responseForVrgGet.data.loanDetails)
      }
      LoanDetailsreasonStatus(responseForVrgGet.data)
      setLoanDetailsObj(responseForVrgGet.data)
      if (!responseForVrgGet.data.loanDetails.settlement) {
        responseForVrgGet.data.loanDetails.settlement = {
          purchasePrice: data.purchasePrice,
          tradeInAmount:
            data?.tradeIn?.assetType === APPLICATION_TRADE_IN_VEHICLE
              ? data?.tradeIn?.tradeInAmount
              : '',
          depositAmount: data.vehicle?.financeDepositAmount
            ? data.vehicle?.financeDepositAmount
            : '',
          balloonPct: data.balloonPct,
          loanTermYears: data.loanTermYears,
          balloonAmount: data.balloonAmount,
          monthlyRepayment: '',
          baseRate: '',
          customerRate: '',
          accountKeepingFee: '',
          lenderEstablishmentFee: '',
          partnerFinanceReferralFee: '',
          ppsrFee: '',
          brokerFee: '',
          lendingCommision: '',
          warranty: {
            provider: '',
            product: '',
            wholesale: '',
            retail: '',
            commission: '',
          },
        }
      } else {
        if (
          responseForVrgGet.data?.loanDetails?.settlement?.warranty?.provider
        ) {
          const warrantyUrl = `/accounts/${responseForVrgGet.data.loanDetails.settlement.warranty.provider}`
          const warrantyUrlResponse = await this.$axios.get(warrantyUrl)
          if (warrantyUrlResponse) {
            responseForVrgGet.data.loanDetails.settlement.warranty.providerData =
              warrantyUrlResponse.data
          }
        }
      }

      if (!responseForVrgGet.data.loanDetails.settlement.insurance) {
        responseForVrgGet.data.loanDetails.settlement.insurance = {
          provider: '',
          policyInfo: '',
          premium: '',
          commission: '',
        }
      } else {
        if (responseForVrgGet.data.loanDetails.settlement.insurance.provider) {
          const insuranceUrl = `/accounts/${responseForVrgGet.data.loanDetails.settlement.insurance.provider}`
          const insuranceUrlResponse = await this.$axios.get(insuranceUrl)

          if (insuranceUrlResponse) {
            responseForVrgGet.data.loanDetails.settlement.insurance.providerData =
              insuranceUrlResponse.data
          }
        }
      }
      if (loanDetails.quotesData) {
        responseForVrgGet.data.quotesData = loanDetails.quotesData
      }
      if (loanDetails?.loanDetails?.lenderData) {
        responseForVrgGet.data.loanDetails.lenderData =
          loanDetails.loanDetails.lenderData
      }
      const isDiffExists = await checkTwoObjectDiffExists(
        JSON.parse(JSON.stringify(data)),
        JSON.parse(JSON.stringify(state.cloneApplication))
      )
      if (isDiffExists) {
        $nuxt.$emit('close-loader')
        const uuID = Math.random().toString().slice(2, 11)
        sessionStorage.setItem('uniquePatchRequestID', uuID)
        const applicationMatchesFormData = {
          vRGId: { vehicleRequestGroup: id },
          vehicle: { ...data.vehicle },
          application: { ...data },
        }
        fetch(`${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches`, {
          method: 'POST',
          body: JSON.stringify(applicationMatchesFormData),
          headers: {
            'Content-type': 'application/json',
          },
        })

        commit('setMatchesGeneratedFalse')
        commit('setlenderMatchesSettings', {})

        commit('setNewCloneApplication', data)
      }
      commit('setNewApplication', responseForVrgGet.data)

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async generateProposal(
    { commit },
    { id, applicationId, shouldNotifyTheError = true }
  ) {
    try {
      const generateProposalDisclousureUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/generate-proposal-disclosure`
      await this.$axios.post(generateProposalDisclousureUrl)
      return true
    } catch (error) {
      if (shouldNotifyTheError) {
        this.$notify.error({
          title: 'Error',
          message: axiosErrorMessageExtractor(error),
        })
      } else {
        throw error
      }
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async resendEmailForPendingDocument(
    { commit, dispatch },
    { id, applicationId, applicantId, isManually }
  ) {
    try {
      const resendUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/emails/pending-docs?isManually=${isManually}`
      await this.$axios.post(resendUrl)
      this.$notify.success({
        title: 'Success',
        message: 'Email has been sent successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async sendProposal({ state, commit, dispatch }, { id, applicationId }) {
    try {
      const mailProposalDisclousureUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/mail-proposal-disclosure`
      await this.$axios.post(mailProposalDisclousureUrl)

      const requestUrlForVrgGet = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const responseForVrgGet = await this.$axios.get(requestUrlForVrgGet)
      if (
        _.isString(
          responseForVrgGet.data.primaryApplicantData?.primaryApplicant
        )
      ) {
        const userRequestUrl = `/user/${responseForVrgGet.data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        responseForVrgGet.data.primaryApplicantData.primaryApplicant =
          userResponse.data
      }

      if (responseForVrgGet?.data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(responseForVrgGet.data, this.$axios)
      } else {
        if (
          responseForVrgGet.data.otherApplicants &&
          responseForVrgGet.data.otherApplicants[0] &&
          _.isString(responseForVrgGet.data.otherApplicants[0].applicant)
        ) {
          if (responseForVrgGet.data.otherApplicants.length > 0) {
            const otherUserRequestUrl = `/user/${responseForVrgGet.data.otherApplicants[0].applicant}`
            const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
            responseForVrgGet.data.otherApplicants[0].applicant =
              otherUserResponse.data
          }
        }
      }

      if (!responseForVrgGet.data.loanDetails.approval) {
        responseForVrgGet.data.loanDetails.approval =
          decisionDataBasedOnSubmissionData(responseForVrgGet.data.loanDetails)
      }
      LoanDetailsreasonStatus(responseForVrgGet.data)
      setLoanDetailsObj(responseForVrgGet.data)
      if (responseForVrgGet.data.loanDetails?.lender) {
        const accountUrl = `/accounts/${responseForVrgGet.data.loanDetails?.lender}`
        const accountResponse = await this.$axios.get(accountUrl)
        if (accountResponse) {
          responseForVrgGet.data.loanDetails.lenderData = accountResponse.data
        }
      }
      let vehicleObj = null
      if (
        responseForVrgGet?.data?.vehicle &&
        _.isString(responseForVrgGet?.data?.vehicle)
      ) {
        const vehicleUrl = `/vehicles/${responseForVrgGet.data.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(responseForVrgGet.data, vehicleResponse)
      } else {
        if (responseForVrgGet?.data?.loanType !== LOAN_TYPE_PERSONAL_LOAN) {
          vehicleObj = {
            vIN: responseForVrgGet.data?.vehicle?.vIN || '',
            registrationPlate:
              responseForVrgGet.data?.vehicle?.registrationPlate || '',
            customerNote: responseForVrgGet.data.vehicle?.customerNote || '',
          }
          _.assign(responseForVrgGet.data?.vehicle, vehicleObj)
        }
      }
      if (
        responseForVrgGet?.data?.tradeIn?.vehicle &&
        _.isString(responseForVrgGet?.data?.tradeIn?.vehicle)
      ) {
        const tradeInVehicleUrl = `/vehicles/${responseForVrgGet.data.tradeIn.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(
          responseForVrgGet.data,
          tradeInVehicleResponse.data
        )
      }
      const vrgPayload = JSON.parse(JSON.stringify(state.application))
      if (vrgPayload?.quotesData) {
        responseForVrgGet.data.quotesData = vrgPayload.quotesData
      }
      commit('setNewApplication', responseForVrgGet.data)

      if (
        ![
          APPLICATION_STATUS_PRE_SETTLEMENT,
          APPLICATION_STATUS_SETTLED,
        ].includes(responseForVrgGet.data.status)
      )
        commit('setApplicationStatus', APPLICATION_STATUS_READY_FOR_SUBMISSION)
      await dispatch('patchApplication', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async submitLoanApplication(
    { commit, dispatch },
    { id, applicationId, loanDetails }
  ) {
    try {
      commit('submitLoanApplication')
      await dispatch('patchApplication', { id, applicationId })

      const requestUrlForVrgGet = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const responseForVrgGet = await this.$axios.get(requestUrlForVrgGet)

      if (
        _.isString(responseForVrgGet.data.primaryApplicantData.primaryApplicant)
      ) {
        const userRequestUrl = `/user/${responseForVrgGet.data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        responseForVrgGet.data.primaryApplicantData.primaryApplicant =
          userResponse.data
      }

      if (responseForVrgGet?.data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(responseForVrgGet.data, this.$axios)
      } else {
        if (
          responseForVrgGet.data.otherApplicants &&
          responseForVrgGet.data.otherApplicants[0] &&
          _.isString(responseForVrgGet.data.otherApplicants[0].applicant)
        ) {
          if (responseForVrgGet.data.otherApplicants.length > 0) {
            const otherUserRequestUrl = `/user/${responseForVrgGet.data.otherApplicants[0].applicant}`
            const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
            responseForVrgGet.data.otherApplicants[0].applicant =
              otherUserResponse.data
          }
        }
      }

      if (!responseForVrgGet.data.loanDetails.approval) {
        responseForVrgGet.data.loanDetails.approval =
          decisionDataBasedOnSubmissionData(responseForVrgGet.data.loanDetails)
      }

      let vehicleObj = null
      if (
        responseForVrgGet?.data?.vehicle &&
        _.isString(responseForVrgGet?.data?.vehicle)
      ) {
        const vehicleUrl = `/vehicles/${responseForVrgGet?.data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(responseForVrgGet.data, vehicleResponse)
      } else {
        if (responseForVrgGet?.data?.loanType !== LOAN_TYPE_PERSONAL_LOAN) {
          vehicleObj = {
            vIN: responseForVrgGet?.data?.vehicle?.vIN || '',
            registrationPlate:
              responseForVrgGet.data.vehicle?.registrationPlate || '',
            customerNote: responseForVrgGet.data.vehicle?.customerNote || '',
          }
          _.assign(responseForVrgGet.data.vehicle, vehicleObj)
        }
      }

      if (
        responseForVrgGet?.data?.tradeIn?.vehicle &&
        _.isString(responseForVrgGet?.data?.tradeIn?.vehicle)
      ) {
        const tradeInVehicleUrl = `/vehicles/${responseForVrgGet.data.tradeIn.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(
          responseForVrgGet.data,
          tradeInVehicleResponse.data
        )
      }
      LoanDetailsreasonStatus(responseForVrgGet.data)
      setLoanDetailsObj(responseForVrgGet.data)
      if (responseForVrgGet.data.loanDetails?.lender) {
        const accountUrl = `/accounts/${responseForVrgGet.data.loanDetails?.lender}`
        const accountResponse = await this.$axios.get(accountUrl)
        if (accountResponse) {
          responseForVrgGet.data.loanDetails.lenderData = accountResponse.data
        }
      }
      if (loanDetails.quotesData) {
        responseForVrgGet.data.quotesData = loanDetails.quotesData
      }
      commit('setNewApplication', responseForVrgGet.data)

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateLoanDetailsSettlement(
    { commit, state },
    { id, applicationId, loanDetails }
  ) {
    try {
      if (loanDetails?.vehicle) {
        const vehicleRequestUrl = `/vehicles/${loanDetails?.vehicle?._id}`
        const vehicleResponse = await this.$axios.patch(
          vehicleRequestUrl,
          loanDetails?.vehicle
        )
        commit('updateVehicles', vehicleResponse.data)
      }
      commit('setNewApplication', loanDetails)
      const vrgPayload = JSON.parse(JSON.stringify(state.application))

      if (!_.isString(vrgPayload.primaryApplicantData.primaryApplicant))
        vrgPayload.primaryApplicantData.primaryApplicant =
          vrgPayload.primaryApplicantData.primaryApplicant._id

      if (vrgPayload.otherApplicants && vrgPayload.otherApplicants.length > 0) {
        if (!_.isString(vrgPayload.otherApplicants[0].applicant))
          vrgPayload.otherApplicants[0].applicant =
            vrgPayload.otherApplicants[0].applicant._id
      }
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const requests = await this.$axios.patch(requestUrl, vrgPayload)
      const { data } = requests

      const userRequestUrl = `/user/${data.primaryApplicantData.primaryApplicant}`
      const userResponse = await this.$axios.get(userRequestUrl)
      data.primaryApplicantData.primaryApplicant = userResponse.data

      if (data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(data, this.$axios)
      } else {
        if (data.otherApplicants.length > 0) {
          const otherUserRequestUrl = `/user/${data.otherApplicants[0].applicant}`
          const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
          data.otherApplicants[0].applicant = otherUserResponse.data
        }
      }

      let vehicleObj = null
      if (data?.vehicle && _.isString(data?.vehicle)) {
        const vehicleUrl = `/vehicles/${data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(data, vehicleResponse)
      } else {
        if (data?.loanType !== LOAN_TYPE_PERSONAL_LOAN) {
          vehicleObj = {
            vIN: data.vehicle?.vIN || '',
            registrationPlate: data.vehicle?.registrationPlate || '',
            customerNote: data.vehicle?.customerNote || '',
          }
          _.assign(data.vehicle, vehicleObj)
        }
      }

      if (data?.tradeIn?.vehicle && _.isString(data?.tradeIn?.vehicle)) {
        const tradeInVehicleUrl = `/vehicles/${data?.tradeIn?.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(data, tradeInVehicleResponse.data)
      }
      SetApplicantIdType(data)
      if (data.loanDetails?.lender) {
        const accountUrl = `/accounts/${data.loanDetails.lender}`
        const accountResponse = await this.$axios.get(accountUrl)
        if (accountResponse) {
          data.loanDetails.lenderData = accountResponse.data
        }
      }
      setBusinessDataObj(data)
      if (loanDetails.quotesData) {
        data.quotesData = loanDetails.quotesData
      }
      if (loanDetails?.loanDetails?.lenderData) {
        data.loanDetails.lenderData = loanDetails.loanDetails.lenderData
      }

      commit('setNewApplication', data)

      if (data.applicationType !== LABEL_FOR_COMMERCIAL) {
        const generateProposalDisclousureUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/generate-proposal-disclosure`
        await this.$axios.post(generateProposalDisclousureUrl)
      }
      const isDiffExists = await checkTwoObjectDiffExists(
        JSON.parse(JSON.stringify(data)),
        JSON.parse(JSON.stringify(state.cloneApplication))
      )
      if (isDiffExists) {
        $nuxt.$emit('close-loader')

        const applicationMatchesFormData = {
          vRGId: { vehicleRequestGroup: id },
          vehicle: { ...data.vehicle },
          application: { ...data },
        }
        fetch(`${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches`, {
          method: 'POST',
          body: JSON.stringify(applicationMatchesFormData),
          headers: {
            'Content-type': 'application/json',
          },
        })

        commit('setMatchesGeneratedFalse')
        commit('setlenderMatchesSettings', {})

        commit('setNewCloneApplication', data)
      }
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateInsurance(
    { dispatch, commit },
    { insuranceDetails, id, applicationId }
  ) {
    try {
      commit('setInsurance', insuranceDetails.loanDetails.settlement.insurance)
      try {
        await dispatch('patchApplication', { id, applicationId })
      } catch (error) {
        commit('setInsurance', {
          ...state.cloneApplication.loanDetails.settlement.insurance,
        })
        throw err
      }

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },

  async sendApplicationLinks(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { dispatch, commit },
    { id, applicationId }
  ) {
    try {
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/send-application-link`
      await this.$axios.post(requestUrl)
      await dispatch('fetchApplications', { id, applicationId })
      await dispatch('patchVRGApplication', {
        id,
        status: APPLICATION_LINK_STATUS_SENT,
        applicationId,
      })

      this.$notify.success({
        title: 'Success',
        message: 'Application Link Sent successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },

  async markAsReviewed({ dispatch, commit }, { id, applicationId }) {
    try {
      await dispatch('patchVRGApplication', {
        id,
        status: APPLICATION_LINK_STATUS_MARK_AS_REVIEWED,
        applicationId,
      })

      this.$notify.success({
        title: 'Success',
        message: 'Application mark as reviewed successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },

  async updateStatus({ dispatch, commit }, { id, status, applicationId }) {
    try {
      await dispatch('patchVRGApplication', {
        id,
        status,
        applicationId,
      })

      this.$notify.success({
        title: 'Success',
        message: 'Application Link Status updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateWarranty(
    { dispatch, commit, state },
    { warrantyDetails, id, applicationId }
  ) {
    try {
      commit('setWarranty', warrantyDetails.loanDetails.settlement.warranty)
      try {
        await dispatch('patchApplication', { id, applicationId })
      } catch (error) {
        commit('setWarranty', {
          ...state.cloneApplication.loanDetails.settlement.warranty,
        })
        throw err
      }

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateLender({ dispatch, commit }, { sharedData, id, applicationId }) {
    try {
      commit('setNewApplication', sharedData)
      await dispatch('patchApplication', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async getAllProviders() {
    try {
      const getProvidersUrl = `/accounts?isWarrantyProvider=true&type=Commercial`
      const providersRes = await this.$axios.get(getProvidersUrl)

      return providersRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async getAllInsuranceProviders() {
    try {
      const getProvidersUrl = `/accounts?isInsurer=true&type=Commercial`
      const providersRes = await this.$axios.get(getProvidersUrl)

      return providersRes
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async getAccountDetails({ commit }, { id }) {
    const accountUrl = `/accounts/${id}`
    const accountResponse = await this.$axios.get(accountUrl)
    if (accountResponse) {
      commit('setAccountDetails', accountResponse.data)
    }
  },

  async settlementApplication(
    { commit, state },
    { id, applicationId, loanDetails, preSettled = false, settled = false }
  ) {
    try {
      if (loanDetails?.vehicle && typeof loanDetails?.vehicle !== 'string') {
        const vehicleRequestUrl = `/vehicles/${loanDetails?.vehicle?._id}`
        const vehicleResponse = await this.$axios.patch(
          vehicleRequestUrl,
          loanDetails.vehicle
        )
        commit('updateVehicles', vehicleResponse.data)
      }

      commit('setNewApplication', loanDetails)
      const vrgPayload = JSON.parse(JSON.stringify(state.application))

      if (!_.isString(vrgPayload.primaryApplicantData.primaryApplicant))
        vrgPayload.primaryApplicantData.primaryApplicant =
          vrgPayload.primaryApplicantData.primaryApplicant._id

      if (vrgPayload.otherApplicants && vrgPayload.otherApplicants.length > 0) {
        if (!_.isString(vrgPayload.otherApplicants[0].applicant))
          vrgPayload.otherApplicants[0].applicant =
            vrgPayload.otherApplicants[0].applicant._id
      }

      if (preSettled) {
        vrgPayload.status = APPLICATION_STATUS_PRE_SETTLEMENT
        vrgPayload.loanDetails.settlement.isPreSettlementSameAsSettlement = true
        vrgPayload.loanDetails.settlement.preSettledAt =
          new Date().toISOString()
        vrgPayload.statusChangedAt = new Date().toISOString()
        vrgPayload.loanDetails.settlement.settlementStatus =
          SETTLEMENT_STATUS_INPROGRESS
      }

      if (settled) {
        vrgPayload.status = APPLICATION_STATUS_SETTLED
        vrgPayload.loanDetails.settlement.settledAt = new Date().toISOString()
        vrgPayload.statusChangedAt = new Date().toISOString()
        if (vrgPayload.loanDetails?.settlement?.settlementStatus) {
          vrgPayload.loanDetails.settlement.settlementStatus =
            APPLICATION_STATUS_SETTLED
        }
      }
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const requests = await this.$axios.patch(requestUrl, vrgPayload)
      const { data } = requests

      if (_.isString(data.primaryApplicantData.primaryApplicant)) {
        const userRequestUrl = `/user/${data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        data.primaryApplicantData.primaryApplicant = userResponse.data
      }

      if (data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(data, this.$axios)
      } else {
        if (data.otherApplicants.length > 0) {
          if (_.isString(data.otherApplicants[0].applicant)) {
            const otherUserRequestUrl = `/user/${data.otherApplicants[0].applicant}`
            const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
            data.otherApplicants[0].applicant = otherUserResponse.data
          }
        }
      }

      let vehicleObj = null
      if (data?.vehicle && _.isString(data?.vehicle)) {
        const vehicleUrl = `/vehicles/${data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        abstractVehicleData(data, vehicleResponse)
      } else {
        if (data?.loanType !== LOAN_TYPE_PERSONAL_LOAN) {
          vehicleObj = {
            vIN: data.vehicle?.vIN || '',
            registrationPlate: data.vehicle?.registrationPlate || '',
            customerNote: data.vehicle?.customerNote || '',
          }
          _.assign(data.vehicle, vehicleObj)
        }
      }

      if (data.tradeIn?.vehicle && _.isString(data.tradeIn?.vehicle)) {
        const tradeInVehicleUrl = `/vehicles/${data.tradeIn?.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(data, tradeInVehicleResponse.data)
      }
      SetApplicantIdType(data)
      setBusinessDataObj(data)
      if (loanDetails.quotesData) {
        data.quotesData = loanDetails.quotesData
      }
      if (loanDetails?.loanDetails?.lenderData) {
        data.loanDetails.lenderData = loanDetails.loanDetails.lenderData
      }

      commit('setNewApplication', data)

      const requestUrlForVrgGet = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const responseForVrgGet = await this.$axios.get(requestUrlForVrgGet)

      if (
        _.isString(responseForVrgGet.data.primaryApplicantData.primaryApplicant)
      ) {
        const userRequestUrl = `/user/${responseForVrgGet.data.primaryApplicantData.primaryApplicant}`
        const userResponse = await this.$axios.get(userRequestUrl)
        responseForVrgGet.data.primaryApplicantData.primaryApplicant =
          userResponse.data
      }

      if (responseForVrgGet?.data?.otherApplicants?.length > 1) {
        await getOtherApplicantData(responseForVrgGet.data, this.$axios)
      } else {
        if (
          responseForVrgGet.data.otherApplicants &&
          responseForVrgGet.data.otherApplicants[0] &&
          _.isString(responseForVrgGet.data.otherApplicants[0].applicant)
        ) {
          if (responseForVrgGet.data.otherApplicants.length > 0) {
            const otherUserRequestUrl = `/user/${responseForVrgGet.data.otherApplicants[0].applicant}`
            const otherUserResponse = await this.$axios.get(otherUserRequestUrl)
            responseForVrgGet.data.otherApplicants[0].applicant =
              otherUserResponse.data
          }
        }
      }

      if (
        responseForVrgGet?.data?.vehicle &&
        _.isString(responseForVrgGet?.data?.vehicle)
      ) {
        const vehicleUrl = `/vehicles/${responseForVrgGet?.data?.vehicle}`
        const vehicleResponse = await this.$axios.get(vehicleUrl)
        responseForVrgGet.data.vehicle = vehicleResponse.data
      }

      if (
        responseForVrgGet?.data?.tradeIn?.vehicle &&
        _.isString(responseForVrgGet?.data?.tradeIn?.vehicle)
      ) {
        const tradeInVehicleUrl = `/vehicles/${responseForVrgGet.data.tradeIn.vehicle}`
        const tradeInVehicleResponse = await this.$axios.get(tradeInVehicleUrl)
        abstractTradeVehicleData(
          responseForVrgGet.data,
          tradeInVehicleResponse.data
        )
      }

      if (!responseForVrgGet.data.loanDetails.approval) {
        responseForVrgGet.data.loanDetails.approval =
          decisionDataBasedOnSubmissionData(responseForVrgGet.data.loanDetails)
      }
      if (responseForVrgGet.data.loanDetails?.lender) {
        const accountUrl = `/accounts/${responseForVrgGet.data.loanDetails?.lender}`
        const accountResponse = await this.$axios.get(accountUrl)
        if (accountResponse) {
          responseForVrgGet.data.loanDetails.lenderData = accountResponse.data
        }
      }
      responseForVrgGet.data.quotesData = loanDetails.quotesData
      const isDiffExists = await checkTwoObjectDiffExists(
        JSON.parse(JSON.stringify(data)),
        JSON.parse(JSON.stringify(state.cloneApplication))
      )
      if (isDiffExists) {
        $nuxt.$emit('close-loader')

        const applicationMatchesFormData = {
          vRGId: { vehicleRequestGroup: id },
          vehicle: { ...data.vehicle },
          application: { ...data },
        }
        fetch(`${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches`, {
          method: 'POST',
          body: JSON.stringify(applicationMatchesFormData),
          headers: {
            'Content-type': 'application/json',
          },
        })

        commit('setMatchesGeneratedFalse')
        commit('setlenderMatchesSettings', {})

        commit('setNewCloneApplication', data)
      }
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async updateBusinessDetails(
    { commit, dispatch },
    { applicationData, id, applicationId }
  ) {
    try {
      commit('setBusinessDetails', applicationData.businessData)
      commit('setOtherApplicantData', applicationData.otherApplicants)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateAccountantDetails(
    { commit, dispatch },
    { accountantData, id, applicationId }
  ) {
    try {
      commit('setBusinessAccountDetails', accountantData)
      await dispatch('patchApplication', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async setNote({ commit, dispatch }, { notes, id, applicationId }) {
    try {
      commit('setNotes', notes)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateNote({ commit, dispatch }, { type, notes, id, applicationId }) {
    try {
      commit('updateNotes', { notes, type })
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateBusinessAddress(
    { commit, dispatch },
    { businessAddress, id, applicationId }
  ) {
    try {
      commit('setBusinessAddress', businessAddress)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateShareHolderDetails(
    { commit, dispatch },
    { formData, id, applicationId }
  ) {
    try {
      commit('setShareHolderData', formData)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateBeneficiariesDetails(
    { commit, dispatch },
    { formData, id, applicationId }
  ) {
    try {
      commit('setBeneficiariesData', formData)
      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateContactAssetsLiabilities(
    { commit, dispatch },
    {
      id,
      expenses,
      liabilities,
      assets,
      hasConfirmableCredit,
      isPrimary = true,
      secondaryIndex = 0,
      applicationId,
    }
  ) {
    try {
      const { assets: bindedAssets, liabilities: bindedLiabilities } =
        bindLiabilityWithAssetIfHas(assets, liabilities)
      commit('setConfirmableCredit', {
        isPrimary,
        hasConfirmableCredit,
        index: secondaryIndex,
      })
      if (isPrimary) {
        commit('setPrimaryAssets', bindedAssets)
        commit('setPrimaryLiabilities', bindedLiabilities)
        commit('setPrimaryExpenses', { expenses })
      } else {
        commit('setSecondaryAssetsByIndex', {
          index: secondaryIndex,
          assets: bindedAssets,
        })
        commit('setSecondaryLiabilitiesByIndex', {
          index: secondaryIndex,
          liabilities: bindedLiabilities,
        })
        commit('setSecondaryExpensesByIndex', {
          expenses,
          index: secondaryIndex,
        })
      }

      await dispatch('patchApplication', { id, applicationId })

      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
    } catch (error) {
      console.log('error ', error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async getReferrerAccount({}, { accountId }) {
    try {
      const url = `/accounts/${accountId}`
      return this.$axios.get(url)
    } catch (err) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(err),
      })
    }
  },

  async generateLenderForDynamicMatches(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state },
    { id, applicationId, applicationMatchesFormData }
  ) {
    try {
      const generateQuotesUrl = `vehicle-request-groups/${id}/finance/applications/${applicationId}/regenerate-lender-matches?source=Fuel`
      const response = await this.$axios.post(
        generateQuotesUrl,
        applicationMatchesFormData
      )
      return response
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: 'Error getting the requests :(',
      })
    }
  },
  async regenerateLenderMatches({ dispatch }, { vrgId, applicationId }) {
    try {
      const regenerateQuotesUrl = `/vehicle-request-groups/${vrgId}/finance/applications/${applicationId}/new-finance-quotes?source=Fuel`
      await this.$axios.get(regenerateQuotesUrl)
      await dispatch('fetchApplications', { id: vrgId, applicationId })
    } catch (err) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(err),
      })
      throw err
    }
  },
  async generateLenderFirstMatches(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    {},
    { id, applicationMatchesFormData = {} }
  ) {
    try {
      const responseData = await fetch(
        `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches/vrg/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        }
      )
      const lenderResponse = await responseData.json()
      if (
        !lenderResponse?.matches?.length &&
        !lenderResponse?.notMatched?.length
      ) {
        const matchesData = await fetch(
          `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches`,
          {
            method: 'POST',
            body: JSON.stringify(applicationMatchesFormData),
            headers: {
              'Content-type': 'application/json',
            },
          }
        )

        return await matchesData.json()
      } else {
        if (_.isString(lenderResponse?.facts?.vehicle)) {
          const vehicleUrl = `/vehicles/${lenderResponse?.facts?.vehicle}`
          const vehicleResponse = await this.$axios.get(vehicleUrl)
          lenderResponse.facts.vehicle = vehicleResponse?.data
        }

        if (_.isString(lenderResponse?.facts?.tradeIn?.vehicle)) {
          const tradeInVehicleUrl = `/vehicles/${lenderResponse?.facts?.tradeIn?.vehicle}`
          const tradeInVehicleResponse = await this.$axios.get(
            tradeInVehicleUrl
          )
          lenderResponse.facts.tradeIn = tradeInVehicleResponse?.data
        }

        return lenderResponse
      }
    } catch (error) {
      console.log(error)
    }
  },
  async generateMatches(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state },
    { id, applicationMatchesFormData = {} }
  ) {
    try {
      const response = await fetch(
        `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches/vrg/${id}/adjust`,
        {
          method: 'PATCH',
          body: JSON.stringify(applicationMatchesFormData),
          headers: {
            'Content-type': 'application/json',
          },
        }
      ).then((response) => {
        if (response.status !== 204) {
          return response.json()
        }
        return
      })
      return response
    } catch (error) {
      console.log(error)
    }
  },
  async generateLenderMatches(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state },
    { applicationMatchesFormData }
  ) {
    try {
      const response = await fetch(
        `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches`,
        {
          method: 'POST',
          body: JSON.stringify(applicationMatchesFormData),
          headers: {
            'Content-type': 'application/json',
          },
        }
      ).then((response) => {
        return response.json()
      })
      return response
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async globalSettingsLenderMatches(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state },
    { id, globalFilters }
  ) {
    try {
      const responseData = await fetch(
        `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches/vrg/${id}/adjust`,
        {
          method: 'PATCH',
          body: JSON.stringify(globalFilters),
          headers: {
            'Content-type': 'application/json',
          },
        }
      )
      return await responseData.json()
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async globalSettingsForQuickQuote(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state },
    { id, globalFilters }
  ) {
    try {
      const responseData = await fetch(
        `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/quickquote`,
        {
          method: 'POST',
          body: JSON.stringify(globalFilters),
          headers: {
            'Content-type': 'application/json',
          },
        }
      )
      return await responseData.json()
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async adjustIndividualMatchForQuickQuote(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state },
    { matchId, individualFilters }
  ) {
    try {
      const responseData = await fetch(
        `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/quickquote/financeproduct/${matchId}`,
        {
          method: 'POST',
          body: JSON.stringify(individualFilters),
          headers: {
            'Content-type': 'application/json',
          },
        }
      )
      return await responseData.json()
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async adjustIndividualMatch(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state },
    { id, matchId, individualFilters }
  ) {
    try {
      const responseData = await fetch(
        `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/matches/vrg/${id}/adjust/${matchId}`,
        {
          method: 'PATCH',
          body: JSON.stringify(individualFilters),
          headers: {
            'Content-type': 'application/json',
          },
        }
      )
      return await responseData.json()
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateSettlementUser(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state },
    { id, applicationId, agentId }
  ) {
    try {
      const updateSettlementUserUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/assign-user/${agentId}?assignFor=settlement`
      const response = await this.$axios.patch(updateSettlementUserUrl)
      return response
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },

  async updateSettlementChecklistItem(
    { commit },
    { id, applicationId, payload }
  ) {
    try {
      const updateSettlementChecklistItemUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/settlement-checklist-item`
      const response = await this.$axios.patch(
        updateSettlementChecklistItemUrl,
        payload
      )
      if (response?.status === 200) {
        await commit('SetsettlementChecklistItem', { data: response.data })
      }
      let successMsg = 'Settlement checklist item updated successfully'
      this.$notify.success({
        title: 'Success',
        message: successMsg,
      })

      return response
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async saveChecklistNotes({ commit }, { id, applicationId, loanDetails }) {
    try {
      const saveChecklistNotesUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const response = await this.$axios.patch(
        saveChecklistNotesUrl,
        loanDetails
      )
      if (loanDetails.quotesData) {
        response.data.quotesData = loanDetails.quotesData
      }
      if (loanDetails?.loanDetails?.lenderData) {
        response.data.loanDetails.lenderData =
          loanDetails.loanDetails.lenderData
      }
      commit('setSettlementNotes', { data: response.data })
      let successMsg = 'Settlement checklist Note saved successfully'
      this.$notify.success({
        title: 'Success',
        message: successMsg,
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getCarEstimatePrice({ commit }, { payload }) {
    try {
      const response = await this.$axios.post(`/vehicles`, payload)
      return response
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getCarImage({ commit }, { makeCode }) {
    try {
      const response = await this.$axios.get(
        `/vehicles-meta-data/vehicle-images?code=${makeCode}`
      )
      return response
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async saveSettlementSatus({ commit, state }, { id, applicationId, event }) {
    try {
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const saveSettlementStatus = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const vrgPayload = JSON.parse(JSON.stringify(state.application))
      vrgPayload.loanDetails.settlement.settlementStatus = event
      const response = await this.$axios.patch(saveSettlementStatus, vrgPayload)
      const status = response.data.loanDetails.settlement.settlementStatus
      commit('setSettlementStatus', { status: status })
      const successMsg = 'Settlement status updated successfully'
      this.$notify.success({
        title: 'Success',
        message: successMsg,
      })
      return response
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async setSettlementEta(
    { commit, state },
    { id, applicationId, settlementEtaDate }
  ) {
    try {
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const updateSettlementETA = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const vrgPayload = JSON.parse(JSON.stringify(state.application))
      vrgPayload.loanDetails.approval.settlementEta = settlementEtaDate
      const response = await this.$axios.patch(updateSettlementETA, vrgPayload)
      const date = response.data.loanDetails?.approval?.settlementEta
      commit('setSettlementETA', { date: date })
      const successMsg = 'Settlement ETA updated successfully'
      this.$notify.success({
        title: 'Success',
        message: successMsg,
      })
      return response
    } catch (error) {
      console.log('Error:-', error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async updateSellerDetails(
    { commit, state },
    { id, applicationId, sellerDetails }
  ) {
    try {
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const updateSellerDetailsUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}`
      const vrgPayload = JSON.parse(JSON.stringify(state.application))
      vrgPayload.loanDetails.settlement.sellerDetails = sellerDetails
      const response = await this.$axios.patch(
        updateSellerDetailsUrl,
        vrgPayload
      )
      const data = response.data.loanDetails?.settlement?.sellerDetails
      commit('setSellerDetails', data)
      const successMsg = 'Seller details updated successfully'
      this.$notify.success({
        title: 'Success',
        message: successMsg,
      })
      return response
    } catch (error) {
      console.log('Error:-', error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async validateDocuments({ commit, state }, { id, applicationId }) {
    const validateMissingDocuments = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/validate-documents`
    const response = await this.$axios.post(validateMissingDocuments)
    return response
  },
  async generateQuickQuoteLenderMatches(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, state },
    { applicationMatchesFormData }
  ) {
    // try {
    //   const response = await this.$axios.post(`/quickquote`,applicationMatchesFormData)
    //   return response
    // }
    try {
      const responseData = await fetch(
        `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/quickquote`,
        {
          method: 'POST',
          body: JSON.stringify(applicationMatchesFormData),
          headers: {
            'Content-type': 'application/json',
          },
        }
      )
      return await responseData.json()
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async setlenderMatchesSettings({ commit }, { data }) {
    commit('setlenderMatchesSettings', data)
  },
  async updateLenderMatchesApplicationMatchesForm({ commit }, { data }) {
    commit('setLenderMatchesApplicationMatchesForm', data)
  },
  async updateMatchesGenerated({ commit }, { data }) {
    commit('setMatchesGenerated', data)
  },
  async uploadDocument(
    { commit, dispatch },
    { id, applicationId, applicantId, formData, isShared }
  ) {
    try {
      if (documentRequestSource) {
        documentRequestSource.cancel()
      }
      const documentRequestCancelToken = this.$axios.CancelToken
      documentRequestSource = documentRequestCancelToken.source()
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      const subUrl = isShared ? `?isShared=${isShared}` : ''
      const requestUrl = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/documents/upload${subUrl}`
      await this.$axios.post(
        requestUrl,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        {
          cancelToken: documentRequestSource.token,
        }
      )
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      await dispatch('fetchApplications', { id, applicationId })
      this.$notify.success({
        title: 'Success',
        message: 'File uploaded successfully!',
      })
    } catch (error) {
      if (!this.$axios.isCancel(error)) {
        const err =
          error.response?.data.error || axiosErrorMessageExtractor(error)
        this.$notify.error({
          title: 'Error',
          message: err,
        })
      }
    }
  },
  async deleteSelectedDocument(
    { commit, dispatch },

    { id, applicationId, documentId, applicantId, isShared }
  ) {
    try {
      const validateURL = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/delete-selected-document/${documentId}?isShared=${isShared}`
      await this.$axios.delete(validateURL)
      await dispatch('fetchApplications', { id, applicationId })

      this.$notify.success({
        title: 'Success',

        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    }
  },
  async deleteUploadedDocument(
    { commit, dispatch },

    { id, applicationId, documentId, applicantId, isShared }
  ) {
    try {
      const validateURL = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/applicants/${applicantId}/delete-uploaded-document/${documentId}?isShared=${isShared}`
      await this.$axios.delete(validateURL)
      await dispatch('fetchApplications', { id, applicationId })

      this.$notify.success({
        title: 'Success',

        message: 'Application updated successfully',
      })
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
  async createXeroInvoice(
    { commit },

    { id, applicationId, lenderBusinessName }
  ) {
    try {
      const validateURL = `/vehicle-request-groups/${id}/finance/applications/${applicationId}/create-xero-invoice`
      const res = await this.$axios.post(validateURL)
      const application = res.data.finance.applications.find(
        (app) => app._id === applicationId
      )
      commit('setXeroInvoiceProps', {
        tpInvoiceId: application.tpInvoiceId,
        tpInvoiceCreatedAt: application.tpInvoiceCreatedAt,
        tpInvoiceCreationAttemptedAt: application.tpInvoiceCreationAttemptedAt,
        tpInvoiceURL: application.tpInvoiceURL,
        tpInvoiceErrorReason: application.tpInvoiceErrorReason,
      })
      if (application?.tpInvoiceErrorReason === 'Invalid lender info') {
        throw new Error(
          `Unable to create the accounts invoice as lender account ${
            lenderBusinessName || ''
          } not found. Please check with support and try again.`
        )
      } else if (
        application?.tpInvoiceErrorReason?.includes('Duplicate Invoice #,')
      ) {
        const invoiceNumber = application?.tpInvoiceErrorReason.match(
          /Duplicate Invoice #, ([\w-]+)/
        )[1]
        throw new Error(
          `Invoice ${invoiceNumber} already exists. Please check with support for more details.`
        )
      } else if (
        application?.tpInvoiceErrorReason?.includes(
          'Both lending commission and origination fee are 0'
        )
      ) {
        throw new Error(
          'Accounts invoice is not created because both the broker fee and lending commission are zero. Please contact support for any further inquiry.'
        )
      } else if (application?.tpInvoiceErrorReason) {
        throw new Error(
          'Unable to create the accounts invoice. Please check with support and try again.'
        )
      } else {
        this.$notify.success({
          title: 'Success',

          message: 'Invoice created Successfully.',
        })
      }
    } catch (error) {
      console.log('Error:-', error)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(error, error.message),
      })
    } finally {
      commit('setAppLoaded', true)
    }
  },
}
